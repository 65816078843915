import { defaultTheme } from '@agro-club/agroclub-shared'
import { isBrazil, isRussia, isVisibleCredit } from 'env'
import { getDealPaidSum } from 'helpers/getDealPaidSum'
import { getPriceTypeLabel } from 'modules/domain/bid/utils'
import formatPrice from 'modules/utils/numbers/formatPrice'
import { formatPriceLogistics, getPriceLogisticsLabel } from 'modules/utils/numbers/formatPriceLogistics'
import { formatQuantity, VerticalFormatQuantity } from 'modules/utils/numbers/formatQuantity'
import { formatTarif } from 'modules/utils/numbers/formatTarif'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { Card } from 'views/components/Card/Card'
import { KeyValue } from 'views/components/KeyValue/KeyValue'
import { TextWithSubText } from 'views/components/TextWithSubText/TextWithSubText'
import { PriceCarrierBlock } from 'views/pages/Deal/DealDetail/PriceCarrierBlock'
import { FormulaTooltip } from '../../components/FormulaTooltip'
import { useDealContext } from '../DealContext'
import { formatTurnoverDays } from '../helpers'
import { MapButtons } from '../MappButtons/MapButtons'
import { PriceWithBroker } from './PriceWithBroker'

const getShipmentTranslationKeys = () => {
  if (isBrazil) {
    return {
      picked_up: 'shipments.picked_up_quantity',
      delivered: 'shipments.delivered_quantity',
    }
  }
  return {
    picked_up: 'fields.loaded',
    delivered: 'fields.uploaded',
  }
}

export const GeneralInfoContent: FC = () => {
  const { t } = useTranslation('deal')
  const { deal } = useDealContext()

  let freightRateLabel = getPriceLogisticsLabel()
  if (isBrazil && deal.logistics_rate_source) {
    freightRateLabel = `${t('fields.freight_rate_max')} ${
      deal.logistics_rate_source && isBrazil ? `(${t(`tarifSources.${deal.logistics_rate_source}`)})` : ''
    }`
  }

  const isPaymentDelay = isRussia
  const shipmentTranslationKeys = getShipmentTranslationKeys()
  const salePrice = (
    <PriceWithBroker
      price={deal.sale_price}
      commision={deal.seller_broker ? deal.seller_broker.commission : undefined}
      sign="+"
    />
  )
  const purchasePrice = (
    <PriceWithBroker
      price={deal.purchase_price}
      commision={deal.customer_broker ? deal.customer_broker.commission : undefined}
      sign="-"
    />
  )

  const isMobile = useMediaQuery({
    query: `(max-width: ${defaultTheme.queries.mobile})`,
  })

  return (
    <Card.Grid cols={isMobile ? 2 : 3} maxColumnWidth={isMobile ? '' : '200px'} columnGap={8}>
      <KeyValue label={t('fields.quantityShort')}>
        {isBrazil ? <VerticalFormatQuantity valueTons={deal.quantity} /> : formatQuantity(deal.quantity)}
      </KeyValue>
      {!isMobile && (
        <KeyValue label={t('fields.distance')}>
          <MapButtons deal={deal} />
        </KeyValue>
      )}
      {isPaymentDelay ? (
        <KeyValue label={t('fields.paymentDelay')}>
          {deal.payment_delay_days !== null
            ? `${deal.payment_delay_days} ${t('fields.days')}`
            : t('common:notSpecified')}
        </KeyValue>
      ) : (
        <div></div>
      )}
      {isMobile && <MapButtons deal={deal} isMobile />}
      <KeyValue label={getPriceTypeLabel(deal.sale_bid.price_type, t)}>
        {isBrazil ? (
          <TextWithSubText
            text={salePrice}
            subText={`${t('common:tax')} ${formatTarif(deal.tax_from_exw)}`}
            subColor="accent"
          />
        ) : (
          salePrice
        )}
      </KeyValue>
      <KeyValue label={getPriceTypeLabel(deal.purchase_bid.price_type, t)}>{purchasePrice}</KeyValue>
      <KeyValue label={t('fields.price_with_logistics')}>
        {isBrazil ? (
          <TextWithSubText
            text={formatPriceLogistics(deal.price_with_logistics_per_ton)}
            subText={formatTarif(deal.price_with_logistics)}
          />
        ) : (
          formatTarif(deal.price_with_logistics)
        )}
      </KeyValue>
      <KeyValue label={freightRateLabel}>
        {isBrazil ? (
          <TextWithSubText
            text={formatPriceLogistics(deal.price_logistics)}
            subText={`${t('common:tax')} ${formatTarif(deal.tax_from_logistics_price)}`}
            subColor="accent"
          />
        ) : (
          <FormulaTooltip title={t('formulaWarning')} numberFormula={deal.price_logistics_formula}>
            {formatPriceLogistics(deal.price_logistics)}
          </FormulaTooltip>
        )}
      </KeyValue>
      <PriceCarrierBlock priceCarrier={deal.price_carrier} />
      <KeyValue label={deal.fixed_margin_by_export_contract ? t('export:contractPrice') : t('fields.fixed_margin')}>
        {formatPrice(deal?.fixed_margin ?? '0', true)}
      </KeyValue>
      <KeyValue label={t('fields.paid_sum')}>{getDealPaidSum(deal)}</KeyValue>
      {!!deal.loaded_quantity && (
        <KeyValue label={t(shipmentTranslationKeys.picked_up)}>
          {isBrazil ? (
            <VerticalFormatQuantity valueTons={deal.loaded_quantity} />
          ) : (
            formatQuantity(deal.loaded_quantity)
          )}
        </KeyValue>
      )}
      {!!deal.delivered_quantity && (
        <KeyValue label={t(shipmentTranslationKeys.delivered)}>
          {isBrazil ? (
            <VerticalFormatQuantity valueTons={deal.delivered_quantity} />
          ) : (
            formatQuantity(deal.delivered_quantity)
          )}
        </KeyValue>
      )}
      {isVisibleCredit && (
        <>
          <KeyValue label={t('turnoverDaysPlan')}>{formatTurnoverDays(deal.turnover_days)}</KeyValue>
          <KeyValue label={t('turnoverDaysActual')}>{formatTurnoverDays(deal.actual_turnover_days)}</KeyValue>
        </>
      )}
    </Card.Grid>
  )
}
