import { FC } from 'react'
import { ListDeal } from 'modules/domain/deal/types'
import { useTags } from './hooks'
import styled from 'styled-components'

type Props = {
  deal: ListDeal
}

const StyledTags = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 4px;
`

export const DealTags: FC<Props> = ({ deal }) => {
  const availableTags = useTags().filter((tag) => tag.isVisible(deal))

  if (availableTags.length > 0) {
    return <StyledTags>{availableTags.map((tag, index) => tag.render(deal, index))}</StyledTags>
  }

  return null
}
