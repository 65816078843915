import { useTranslation } from 'react-i18next'
import { ColumnsType } from 'antd/lib/table'
import { TeamsPlansByMembers } from 'modules/domain/teams/types'
import { generatePath } from 'react-router-dom'
import TeamsRoutes from 'views/pages/Teams/routes'
import formatNumber from 'modules/utils/numbers/formatNumber'
import { useMemo } from 'react'
import env from 'env'
import { TeamsProgress } from 'views/components/TeamsProgress/TeamsProgress'
import { TableTextLink } from 'views/ui/TableTextLink/TableTextLink'

const currency = env.REACT_APP_CURRENCY_SYMBOL

export const useTeamColumns = (): ColumnsType<TeamsPlansByMembers> => {
  const { t } = useTranslation('teams')

  return useMemo(
    () => [
      {
        title: t('team'),
        dataIndex: 'teamName',
        key: 'teamName',
        width: '138px',
        render: (teamName, data) => (
          <TableTextLink id="team" to={generatePath(TeamsRoutes.Details, { id: data.team })} key={data.team}>
            {teamName}
          </TableTextLink>
        ),
      },
      {
        title: t('monthly_plan', { currency }),
        dataIndex: 'volume',
        key: 'volume',
        align: 'right',
        width: '122px',
        render: (volume) => formatNumber(volume),
      },
      {
        title: t('month_results', { currency }),
        dataIndex: 'completed',
        key: 'completed',
        align: 'right',
        width: '155px',
        render: (_, memberList) => (
          <TeamsProgress align="flex-end" isTableCell volume={memberList.volume} completed={memberList.completed} />
        ),
      },
    ],
    [t],
  )
}
