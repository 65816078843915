import { FormGroup } from '@agro-club/agroclub-shared'
import { ShortFormControl } from 'views/styled/common'
import { FC } from 'react'
import { AAntDatePicker } from '../Analytics'
import { FormikFieldsType } from 'modules/domain/types'

type Props = {
  formik: FormikFieldsType
  label: string
  fieldName: string
  id: string
  required?: boolean
}

export const FormDatePicker: FC<Props> = ({ formik, label, fieldName, id, required }) => {
  return (
    <ShortFormControl>
      <FormGroup label={label} error={formik.errors[fieldName]} required={required}>
        <AAntDatePicker
          onChange={(value) => formik.setFieldValue(fieldName, value['date'])}
          error={!!formik.errors[fieldName]}
          value={formik.values[fieldName]}
          allowClear
          id={id}
        />
      </FormGroup>
    </ShortFormControl>
  )
}
