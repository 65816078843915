import { CurrencySign, Export, ExportDeliveryBasisMap } from 'modules/domain/export/types'
import { formatQuantity } from 'modules/utils/numbers/formatQuantity'
import formatNumber from 'modules/utils/numbers/formatNumber'
import { ARouterLink } from 'views/components/Analytics'
import ExportRoutes from 'views/pages/Export/routes'
import { Text } from '@agro-club/agroclub-shared'
import { useTranslation } from 'react-i18next'
import useFormatDate from 'hooks/useFormatDate'
import { generatePath } from 'react-router'
import { DASH } from 'modules/constants'
import { FC } from 'react'

type Props = {
  exportData: Export
}

export const ItemTable: FC<Props> = ({ exportData }) => {
  const { t } = useTranslation('export')
  const formatDate = useFormatDate()

  const firstRow = () => {
    const exportLink =
      exportData.number && exportData.contract_date ? (
        <ARouterLink
          to={generatePath(ExportRoutes.Details, { id: exportData.id })}
          itemId={exportData.id}
          eventName="export"
        >
          {t('numberAndContarctDate', { number: exportData.number, date: formatDate(exportData.contract_date) })}
        </ARouterLink>
      ) : (
        DASH
      )

    const deliveryBasis = t(ExportDeliveryBasisMap[exportData.delivery_basis])

    return (
      <>
        {exportLink}, {deliveryBasis}
      </>
    )
  }

  const secondRow = () => {
    const productTitle = exportData?.product.title || DASH
    const quantity = exportData?.quantity ? formatQuantity(Number(exportData.quantity)) : DASH
    const price = exportData?.price ? formatNumber(exportData.price, true) : DASH
    const currency = CurrencySign[exportData?.price_currency] || DASH

    return (
      <Text typography="accentLarge">{`${productTitle}, ${quantity}, ${price} ${t('formatPrice', { currency })}`}</Text>
    )
  }

  const thirdRow = () => {
    const purchaser = exportData.purchaser?.name || DASH
    const ship = exportData.ship?.name || DASH
    return <div>{`${purchaser}, ${ship}`}</div>
  }

  return (
    <div>
      {firstRow()}
      {secondRow()}
      {thirdRow()}
    </div>
  )
}
