import { defaultTheme } from '@agro-club/agroclub-shared'
import { isVisibleCredit } from 'env'
import { t } from 'i18next'
import { FC } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Card } from 'views/components/Card/Card'
import { KeyValue } from 'views/components/KeyValue/KeyValue'
import { formatTurnoverDays } from '../helpers'

type Props = {
  plan: number
  actual: number
}

export const TurnoverDays: FC<Props> = ({ plan, actual }) => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${defaultTheme.queries.mobile})`,
  })

  if (!isVisibleCredit) return null

  return (
    <Card.Container bordered>
      <Card.Grid cols={2} maxColumnWidth={isMobile ? '1fr' : '186px'} columnGap={32}>
        <KeyValue label={t('deal:turnoverDaysPlan')}>{formatTurnoverDays(plan)}</KeyValue>
        <KeyValue label={t('deal:turnoverDaysActual')}>{formatTurnoverDays(actual)}</KeyValue>
      </Card.Grid>
    </Card.Container>
  )
}
