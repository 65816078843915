import { AntTag } from '@agro-club/agroclub-shared'
import { ListDeal } from 'modules/domain/deal/types'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

export const ContributionMarginTag: FC<{ deal: ListDeal }> = ({ deal }) => {
  const { t } = useTranslation('deal')

  return <AntTag color={deal.actual_turnover_days_are_filled ? 'blue' : 'grey'}>{t('contributionMargin')}</AntTag>
}
