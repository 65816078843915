import PermissionCheck from 'views/components/PermissionCheck/PermissionCheck'
import { Verified, WrapperIcon, VerifiedTitle, VerifiedDate } from '../../styled'
import { Icons } from '@agro-club/agroclub-shared'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { refetchFunc } from 'modules/domain/common/hooks'
import { User } from 'modules/domain/user/types'
import { CoordinatorConfirmModal } from './ConfirmModals/CoordinatorConfirmModal'
import useFormatDate from 'hooks/useFormatDate'
import { isBrazil } from 'env'

type Props = {
  userRefetch: refetchFunc
  user: User
}

export const VerifiedByCoordinator = ({ user, userRefetch }: Props) => {
  const { t } = useTranslation('user')
  const formatDate = useFormatDate()
  const [verifyCoordinatorModal, setVerifyCoordinatorModal] = useState(false)

  if (!user.profile) return null

  return (
    <>
      <PermissionCheck scope={['users.can_verify_as_coordinator']}>
        <div>
          <Verified
            isVerified={user.profile.is_verified_by_coordinator}
            isActive={user.is_active}
            onClick={() => setVerifyCoordinatorModal(true)}
          >
            <div>
              <WrapperIcon>
                <Icons.IconCheckMark />
              </WrapperIcon>
              <VerifiedTitle>{t('verify_coordinator')}</VerifiedTitle>
            </div>
          </Verified>
          {user.profile.verified_by_coordinator_at && !isBrazil && (
            <VerifiedDate>{formatDate(user.profile.verified_by_coordinator_at)}</VerifiedDate>
          )}
        </div>
      </PermissionCheck>

      {verifyCoordinatorModal && (
        <CoordinatorConfirmModal
          onClose={() => setVerifyCoordinatorModal(false)}
          refetch={userRefetch}
          userId={user.id}
        />
      )}
    </>
  )
}
