import { defaultTheme, ExternalLink, FormGroup, Icons, Input, Typography } from '@agro-club/agroclub-shared'
import { AUploadFileButton } from 'views/components/UploadFileButton/AUploadFileButton'
import { ExportDocumentType } from 'modules/domain/export/types'
import { AButton } from 'views/components/Analytics'
import { DocumentFile } from 'modules/domain/types'
import { useFileHandler } from './useFileHandler'
import { ExportFormFields } from './AddExport'
import styled from 'styled-components'
import { FormikProps } from 'formik'
import { FC } from 'react'
import { TruncatedValue } from 'views/components/TruncatedValue/TruncatedValue'

type Props = {
  documentType: ExportDocumentType
  formik: FormikProps<ExportFormFields>
  documentField: string
  placeholder?: string
  label: string
  field: string
}
const Wrapper = styled.div`
  display: flex;
  gap: 0 8px;
`

const FileItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0 8px;
  padding: 4px 45px 0 4px;
`
const IconClip = styled(Icons.IconClip)`
  height: 18px;
  width: 18px;
  path {
    fill: ${defaultTheme.color.secondaryPlaceholder3};
  }
`

const FileLink = styled(ExternalLink)`
  color: ${defaultTheme.color.primary600};
  ${Typography.bodyMedium}
  margin: 0 auto 0 0;
  max-width: 250px;
  &:hover {
    text-decoration: underline;
  }
`

export const FileListComponent = ({
  deleteFile,
  files,
}: {
  deleteFile: (id: string) => void
  files: DocumentFile[]
}) => {
  return (
    <div>
      {files.map((file) => (
        <FileItem key={file.id}>
          <IconClip />
          <FileLink href={file.uploaded_file} target="_blank">
            <TruncatedValue hint={file.original_filename}>{file.original_filename}</TruncatedValue>
          </FileLink>
          <AButton
            Icon={Icons.IconDelete}
            intent="white"
            size="medium"
            id="deleteFile"
            onClick={() => deleteFile(file.id)}
          />
        </FileItem>
      ))}
    </div>
  )
}

export const InputWithFiles: FC<Props> = ({ label, field, formik, placeholder, documentType, documentField }) => {
  const { fileResponse, uploadProgress, upload, deleteFile } = useFileHandler(formik, documentField, documentType)

  return (
    <FormGroup label={label} error={formik.errors[field]}>
      <Wrapper>
        <Input {...formik.getFieldProps(field)} placeholder={placeholder} />
        <AUploadFileButton
          eventName="uploadDocument"
          progress={uploadProgress}
          icon={Icons.IconClip}
          onUpload={upload}
          size="big"
          text=""
        />
      </Wrapper>
      <FileListComponent files={fileResponse} deleteFile={deleteFile} />
    </FormGroup>
  )
}
