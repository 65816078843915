import { CommonRegionFilter } from 'views/components/TableFilters/RegionsFilters/CommonRegionFilter'
import { ACheckboxV2, AClearFiltersButton, ASearch, ASelectFilter } from 'views/components/Analytics'
import { ADateRangePicker } from 'views/components/TableFilters/DateRangePicker/ADateRangePicker'
import { CropsFilter } from 'views/components/TableFilters'
import { ABaseTableFilter } from 'views/components/TableFilters/ABaseTableFilter'
import { AddressFilter } from 'views/components/AddressFilter/AddressFilter'
import { CoordinatorTypeFilter } from 'views/filters/CoordinatorTypeFilter'
import { InputFilter } from 'views/components/InputFilter/InputFilter'
import {
  FiltersAndSearchWrapper,
  FiltersWrapper,
  SearchWrapper,
} from 'views/components/CommonTableComponents/CommonTableComponents'
import { isUS, isUserProductTagsEnabled } from 'env'
import { useDisplayClearBtn } from 'hooks/useDisplayClearBtn'
import { AnalyticsFilterVariant } from 'analytics/types'
import { TableFiltersProps } from 'modules/types'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import { FC, useMemo } from 'react'
import { NewTeamFilter } from 'views/components/TeamFilter/NewTeamFilter'

export const UserFilters: FC<TableFiltersProps> = ({ setListRequestParams, updateFilterState, listRequestParams }) => {
  const { t } = useTranslation('user')

  const displayClearBtn = useDisplayClearBtn({
    listRequestParams,
  })

  const optionsType = useMemo(
    () =>
      ['seller', 'customer', 'carrier', 'broker'].map((pType) => ({ value: pType, label: t(`profile_type.${pType}`) })),
    [t],
  )

  const optionsRole = useMemo(
    () => ['coordinator', 'market_user'].map((role) => ({ value: role, label: t(`role.${role}`) })),
    [t],
  )

  const optionsStatus = [
    { value: 'true', label: t('list.isActive') },
    { value: 'false', label: t('list.inActive') },
  ]

  return (
    <FiltersAndSearchWrapper>
      <FiltersWrapper>
        {!isUS && (
          <ADateRangePicker
            value={[listRequestParams.filter.date_joined_after, listRequestParams.filter.date_joined_before]}
            onChange={(value) =>
              updateFilterState({ date_joined_after: value.startDate, date_joined_before: value.endDate })
            }
            id={AnalyticsFilterVariant.DATE}
          />
        )}

        <ASelectFilter
          onChange={(profile_type) => updateFilterState({ profile_type })}
          value={listRequestParams.filter.profile_type}
          placeholder={t('type')}
          options={optionsType}
          showSearch={false}
          id={AnalyticsFilterVariant.TYPE}
        />

        {isUS && (
          <InputFilter
            onChange={(days_since_last_comment) => updateFilterState({ days_since_last_comment })}
            value={listRequestParams.filter.days_since_last_comment}
            id={AnalyticsFilterVariant.DAYS}
            suffix={t('short_day')}
            placeholder={t('days')}
            prefix={t('more')}
            type="number"
          />
        )}

        {isUserProductTagsEnabled && !isUS && (
          <CropsFilter
            listRequestParams={listRequestParams}
            updateFilterState={updateFilterState}
            fieldName="product_tag"
          />
        )}
        <ASelectFilter
          onChange={(profile_role) => updateFilterState({ profile_role })}
          value={listRequestParams.filter.profile_role}
          placeholder={t('roleSingle')}
          options={optionsRole}
          showSearch={false}
          id={AnalyticsFilterVariant.ROLE}
        />
        <CoordinatorTypeFilter updateFilterState={updateFilterState} listRequestParams={listRequestParams} />
        <NewTeamFilter
          eventId={AnalyticsFilterVariant.TEAMS}
          value={listRequestParams.filter.team}
          updateFilterState={updateFilterState}
        />
        <ASelectFilter
          onChange={(is_active) => updateFilterState({ is_active })}
          placeholder={t('list.tableHeaders.status')}
          value={listRequestParams.filter.is_active}
          options={optionsStatus}
          showSearch={false}
          id={AnalyticsFilterVariant.STATUS}
        />
        <ABaseTableFilter
          onChange={(user_feature) => updateFilterState({ user_feature })}
          value={listRequestParams.filter.user_feature}
          id={AnalyticsFilterVariant.FEATURE}
          endpoint={endpoints.userFeature()}
          getLabel={(dto) => dto.label}
          placeholder={t('feature')}
          showSearch={false}
          simpleApiFormat
        />

        <CommonRegionFilter listRequestParams={listRequestParams} updateFilterState={updateFilterState} />

        <AddressFilter filter={listRequestParams.filter} onChange={(searchParams) => updateFilterState(searchParams)} />
        <ACheckboxV2
          onChange={(e) => updateFilterState({ without_tasks: e.target.checked })}
          /* I check for String type because after reloading the page with url, a string is loaded into the "without_tasks" parameter that does not work with the checkbox */
          checked={
            typeof listRequestParams.filter.without_tasks === 'string'
              ? JSON.parse(listRequestParams.filter.without_tasks)
              : listRequestParams.filter.without_tasks
          }
          label={t('noTasks')}
          id="noTasks"
        />
        {displayClearBtn && (
          <AClearFiltersButton
            onClick={() => setListRequestParams && setListRequestParams({ ...listRequestParams, filter: {} })}
          />
        )}
      </FiltersWrapper>

      <SearchWrapper>
        <ASearch
          onChange={(search) => updateFilterState({ search })}
          value={listRequestParams.filter.search}
          placeholder={t('common:search')}
        />
      </SearchWrapper>
    </FiltersAndSearchWrapper>
  )
}
