import { DeliveryBasis, Export, ExportDocumentType } from 'modules/domain/export/types'
import { SharedModalContent, SharedModalInputs } from 'views/components/SharedModal'
import { FormGroup, Input, Radio, RadioItem } from '@agro-club/agroclub-shared'
import { FormItemWrapper, ShortFormControls } from 'views/styled/common'
import { FormDatePicker } from 'views/components/form/FormDatePicker'
import { labelQuantity } from 'modules/utils/numbers/formatQuantity'
import FormSelectShared from 'views/components/FormSelectShared'
import { BoolRadio } from 'views/components/BoolRadio/BoolRadio'
import { ThreeFieldsWithFiles } from './ThreeFieldsWithFiles'
import { FormInput } from 'views/components/form/FormInput'
import { HrWithHeader } from 'views/components/Hr/Hr'
import { PriceUnitSelect } from '../PriceUnitSelect'
import { InputWithFiles } from './InputWithFiles'
import { SelectWithFile } from './SelectWithFile'
import { useTranslation } from 'react-i18next'
import { ExportFormFields } from './AddExport'
import { endpoints } from 'modules/endpoints'
import styled from 'styled-components'
import { FormikProps } from 'formik'
import { FC, ReactNode } from 'react'

type Props = {
  formik: FormikProps<ExportFormFields>
  children?: ReactNode
  exportData?: Export
}

const TwoControls = styled.div`
  display: grid;
  grid-template-columns: 94px auto;
  gap: 8px;
`

export const ThreeFields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px 0;
`

export const CommonFields: FC<Props> = ({ formik, children, exportData }) => {
  const { t } = useTranslation('export')

  const onChangeDubaiControl = (v) => {
    formik.setFieldValue('through_dubai', v)
    formik.setFieldValue('through_dubai_price_currency', null)
    formik.setFieldValue('through_dubai_price', null)
  }

  const onChangeDeliveryBasis = (v) => {
    formik.setFieldValue('delivery_basis', v)

    formik.setFieldValue('freight_currency', null)
    formik.setFieldValue('freight_cost', null)

    formik.setFieldValue('freight_broker', null)
    formik.setFieldValue('freight_broker_cost', null)
    formik.setFieldValue('freight_broker_currency', null)

    formik.setFieldValue('ship', null)
    formik.setFieldValue('ship_owner', null)
  }
  return (
    <SharedModalContent>
      <SharedModalInputs>
        <HrWithHeader text={t('commonInfo')} />
        <FormSelectShared
          endpoint={endpoints.products()}
          getLabel={(dto) => dto.title}
          fieldName="product"
          showSearch={false}
          label={t('crop')}
          formik={formik}
          simpleApiFormat
        />
        <ShortFormControls>
          <FormInput formik={formik} field="quantity" label={labelQuantity(t('volume'))} type="number" />
        </ShortFormControls>
        <FormGroup label={t('deliveryBasis')} error={formik.errors.delivery_basis}>
          <FormItemWrapper>
            <Radio onChange={onChangeDeliveryBasis} value={formik.values.delivery_basis}>
              <RadioItem value={DeliveryBasis.FOB} label={t('fob')} />
              <RadioItem value={DeliveryBasis.CIF} label={t('cif')} />
              <RadioItem value={DeliveryBasis.CFR} label={t('cfr')} />
            </Radio>
          </FormItemWrapper>
        </FormGroup>
        <InputWithFiles
          documentType={ExportDocumentType.CONTRACT}
          documentField="contractDocuments"
          label={t('numberContract')}
          field="number"
          formik={formik}
        />
        <ShortFormControls>
          <FormDatePicker label={t('contractDate')} fieldName="contract_date" id="contract_date" formik={formik} />
          {exportData?.id && (
            <FormDatePicker
              label={t('consignmentDate')}
              fieldName="consignment_date"
              id="consignment_date"
              formik={formik}
            />
          )}
        </ShortFormControls>
        <InputWithFiles
          documentType={ExportDocumentType.UNK}
          documentField="unkDocuments"
          placeholder={t('enterNumber')}
          field="unique_contract_number"
          label={t('unk')}
          formik={formik}
        />
        <FormSelectShared
          endpoint={endpoints.exportPurchasers()}
          getLabel={(dto) => dto.name}
          fieldName="purchaser"
          showSearch={false}
          label={t('buyer')}
          formik={formik}
          simpleApiFormat
          placeholder
        />
        {formik.values.delivery_basis !== DeliveryBasis.CFR && (
          <>
            <FormSelectShared
              endpoint={endpoints.exportShips()}
              getLabel={(dto) => dto.name}
              fieldName="ship"
              showSearch={false}
              label={t('vesselName')}
              formik={formik}
              simpleApiFormat
              placeholder
            />
            <SelectWithFile
              documentType={ExportDocumentType.SHIP_OWNER}
              documentField="shipOwnerDocuments"
              endpoint={endpoints.exportShipOwners()}
              getLabel={(dto) => dto.name}
              fieldName="ship_owner"
              showSearch={false}
              label={t('shipowner')}
              formik={formik}
              simpleApiFormat
              placeholder
            />
          </>
        )}
        <BoolRadio fieldName="through_dubai" formik={formik} onChange={onChangeDubaiControl} label={t('viaDubai')} />
        {formik.values.through_dubai && (
          <FormGroup error={formik.errors.through_dubai_price} label={t('priceThroughDubai')}>
            <PriceUnitSelect
              currencyField="through_dubai_price_currency"
              inputField="through_dubai_price"
              formik={formik}
            />
          </FormGroup>
        )}
        <HrWithHeader text={t('cost')} />

        <FormGroup error={formik.errors.price} label={t('contractValue')}>
          <PriceUnitSelect currencyField="price_currency" inputField="price" formik={formik} />
        </FormGroup>
        {formik.values.delivery_basis !== DeliveryBasis.FOB && (
          <FormGroup error={formik.errors.freight_cost} label={t('freightCost')}>
            <PriceUnitSelect currencyField="freight_currency" inputField="freight_cost" formik={formik} />
          </FormGroup>
        )}
        <FormGroup error={formik.errors.duty_type} label={t('toll')}>
          <TwoControls>
            <Input {...formik.getFieldProps('duty_value')} />
            <FormSelectShared
              endpoint={endpoints.exportDutyTypes()}
              placeholder={t('tollPlaceholder')}
              getLabel={(dto) => dto.label}
              fieldName="duty_type"
              showSearch={false}
              formik={formik}
              simpleApiFormat
              hideLabel
            />
          </TwoControls>
        </FormGroup>

        <FormGroup error={formik.errors.storage_cost} label={t('storage')}>
          <PriceUnitSelect currencyField="storage_currency" inputField="storage_cost" formik={formik} />
        </FormGroup>
        <FormGroup error={formik.errors.documents_cost} label={t('documents')}>
          <PriceUnitSelect currencyField="documents_currency" inputField="documents_cost" formik={formik} />
        </FormGroup>

        <FormGroup error={formik.errors.warehouse} label={t('transshipment')} controlContainerStyle={{ padding: 0 }}>
          <ThreeFields>
            <FormSelectShared
              endpoint={endpoints.warehousesShortList()}
              getLabel={(dto) => dto.title}
              fieldName="warehouse"
              showSearch={false}
              formik={formik}
              simpleApiFormat
              placeholder
              hideLabel
            />
            <PriceUnitSelect currencyField="warehouse_currency" inputField="warehouse_cost" formik={formik} />
          </ThreeFields>
        </FormGroup>
        <ThreeFieldsWithFiles
          endpoint={endpoints.exportFreightBrokers()}
          fieldName="freight_broker"
          documentType={ExportDocumentType.FREIGHT_BROKER}
          documentField="freightBrokerDocuments"
          currencyField="freight_broker_currency"
          inputField="freight_broker_cost"
          formik={formik}
          label={t('freightBroker')}
        />
        <ThreeFieldsWithFiles
          endpoint={endpoints.exportBrokers()}
          fieldName="export_broker"
          documentType={ExportDocumentType.EXPORT_BROKER}
          documentField="exportBrokerDocuments"
          currencyField="export_broker_currency"
          inputField="export_broker_cost"
          formik={formik}
          label={t('exportBroker')}
        />

        <ThreeFieldsWithFiles
          endpoint={endpoints.exportInsurances()}
          fieldName="insurance"
          documentType={ExportDocumentType.INSURANCE}
          documentField="insuranceDocuments"
          currencyField="insurance_currency"
          inputField="insurance_cost"
          formik={formik}
          label={t('insurance')}
        />

        <ThreeFieldsWithFiles
          endpoint={endpoints.exportSurveyors()}
          fieldName="surveyor"
          documentType={ExportDocumentType.SURVEYOR}
          documentField="surveyorDocuments"
          currencyField="surveyor_currency"
          inputField="surveyor_cost"
          formik={formik}
          label={t('surveyor')}
        />

        {children}
      </SharedModalInputs>
    </SharedModalContent>
  )
}
