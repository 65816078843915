import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import { SharedModalTitle } from 'views/components/SharedModal'
import { RequestError } from 'modules/errors'
import { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'
import { useAFormHandler } from 'analytics/hooks'
import ASharedModal from 'views/components/SharedModal/ASharedModal'
import { AnalyticsPlaces } from '../../analyticsPlaces'
import ADefaultModalFooter from 'views/components/DefaultModalFooter/ADefaultModalFooter'
import { FC } from 'react'
import { EditableRatesDTO, RateType, RatesProductTypes } from '../types'
import { getLogisticsRanges } from '../../helpers'
import { ModalContent } from './ModalContent'

interface Props {
  refetch: () => void
  onClose: () => void
  currentProductType?: RatesProductTypes
}

export const AddRatesModal: FC<Props> = ({ onClose, refetch, currentProductType }) => {
  const { formProgress, formHandler } = useAFormHandler('addRates')
  const { t } = useTranslation('rates')

  const initialRates: RateType[] = getLogisticsRanges().map((range) => ({
    rate: undefined,
    distance_range: range,
  }))

  const formik = useFormik<EditableRatesDTO & { countryId?: string }>({
    initialValues: {
      countryId: '',
      sale_regions: [],
      rates: initialRates,
      product_type: currentProductType || '',
      responsible_logisticians: [],
    },
    enableReinitialize: true,

    onSubmit: formHandler(
      async () => {
        const filteredRates = formik.values.rates.filter((item) => item.rate)
        const data = {
          sale_regions: formik.values.sale_regions,
          rates: filteredRates,
          product_type: formik.values.product_type,
          responsible_logisticians: formik.values.responsible_logisticians,
        }

        await apiClient.post<EditableRatesDTO>(endpoints.LogisticsKR.relatedRates(), data)
      },
      {
        onSuccess: async () => {
          await refetch()
          onClose()
        },
        onError: (err) => {
          const error = err as RequestError
          const { errors } = error
          formik.setErrors(errors)
        },
      },
    ),
  })

  return (
    <ASharedModal id={AnalyticsPlaces.RATES.ADD_MODAL} size="medium" onClose={onClose}>
      <SharedModalTitle>{t('modal.addHeading')}</SharedModalTitle>

      <ModalContent formik={formik} />

      <ADefaultModalFooter
        confirmButtonText={t('common:save')}
        onClose={onClose}
        onConfirm={() => formik.dirty && formik.submitForm()}
        progress={formProgress}
        disabled={!formik.dirty}
      />
    </ASharedModal>
  )
}
