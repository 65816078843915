import { isBrazil } from 'env'
import { VerifiedTitle, VerifiedByAll, WrapperIcon, VerifiedDate } from '../../styled'
import { Icons } from '@agro-club/agroclub-shared'
import useFormatDate from 'hooks/useFormatDate'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  coordinatorVerifiedDate: string
  securityVerifiedDate: string
}

export const VerifiedUser: FC<Props> = ({ coordinatorVerifiedDate, securityVerifiedDate }) => {
  const { t } = useTranslation('user')
  const formatDate = useFormatDate()

  return (
    <div>
      <VerifiedByAll>
        <div>
          <WrapperIcon>
            <Icons.IconCheckMark />
          </WrapperIcon>
          <VerifiedTitle>{t('verify_user')}</VerifiedTitle>
        </div>
      </VerifiedByAll>
      {!isBrazil && (
        <>
          <VerifiedDate>
            {t('coordinatorShort')}: {formatDate(coordinatorVerifiedDate)}
          </VerifiedDate>
          <VerifiedDate>
            {t('securityShort')}: {formatDate(securityVerifiedDate)}
          </VerifiedDate>
        </>
      )}
    </div>
  )
}
