import { DateISO, Progress } from '@agro-club/agroclub-shared'

import { Bid, BidContract, BidPrice, Season } from 'modules/domain/bid/types'
import { Company, CompanyShort, FactoringStatus } from 'modules/domain/company/types'
import { Contract } from 'modules/domain/specification/types'
import { Address, NetworkError, Product, RespFile } from 'modules/domain/types'
import { BrokerType, User } from 'modules/domain/user/types'
import { ContractStatuses } from 'views/pages/Contracts/types'
import { RegionalWorkType } from 'views/pages/Deal/DealDetail/types'
import { ProductPurpose } from '../documentRequest/types'

export enum DealType {
  trade = 'trade',
  export = 'export',
  cpt = 'cpt',
  warehouse = 'warehouse',
  broker = 'broker',
}

export enum MarginLevel {
  unknown = 'unknown',
  low = 'low',
  high = 'high',
}

export enum ClosedReason {
  price_ss = 'price_ss',
  price_zz = 'price_zz',
  logistics = 'logistics',
  slow_documents = 'slow_documents',
  no_documents = 'no_documents',
  quality = 'quality',
  small_margin = 'small_margin',
  outer_factors = 'outer_factors',
  better_matches = 'better_matches',
  security_check = 'security_check',
  not_actual_bid = 'not_actual_bid',
  refused_to_sell = 'refused_to_sell',
  payment = 'payment',
}

export enum WhoResponded {
  purchase = 'purchase',
  sale = 'sale',
}

export enum DealSource {
  response = 'response',
  matching = 'matching',
  bid_response = 'bid_response',
  deal_response = 'deal_response',
}

export enum HighlightPrice {
  cpt = 'cpt',
  exw = 'exw',
}

export type Executor = {
  id: string
  full_name: string
  is_active: boolean
  phone?: string
  work_type: RegionalWorkType
}

export type Executors = {
  user_coordinator: Executor
  deal_coordinator: Executor
  shipment_coordinator: Executor
  logistician: Executor
  logisticians: Executor[]
  regionals: Executor[]
  regional?: Executor
  trader: Executor
  ignore_quality?: boolean
}

type AddressShort = {
  id: string
  latitude: number
  longitude: number
}

export type CompanyInDeal = {
  id: string
  name: string
  has_shipped_deals: boolean
  factoring_seller_status?: FactoringStatus
  factoring_customer_status?: FactoringStatus
}

export type ListDealBid = {
  id: string
  owner: User
  price_type: BidPrice
  parent_id?: string
  price: number
  company: CompanyInDeal
  owner_is_significant?: boolean
  owner_is_verified_by_coordinator?: boolean
  owner_is_verified_by_security?: boolean
  owner_is_verified_by_kyc?: boolean
  owner_is_verified?: boolean
  owner_has_shipped_deals?: boolean
  address: AddressShort
}

export type Parameter = {
  id: string
  name: string
  units: string
}

export type DealContractParams = {
  id: string
  parameter: Parameter
  parameter_value: string
}

export type VaidationRecord = {
  text: string
  missing?: boolean
}

export type DealBroker = {
  id: string
  full_name: string
  is_active: boolean
  phone?: string
}

export type BrokerData = {
  broker: DealBroker
  commission: number
  broker_type: BrokerType
}

export type DeliverySide = 'seller' | 'purchaser' | 'agroclub'

export type DealContract = {
  id: string
  deal: string
  approved_by_lawyers: boolean
  farmer_documents_received: boolean
  signed_by_agroclub: boolean
  assignee: Executor
  status: ContractStatuses
  available_statuses: string[]
  conformity_declaration_type: DealContractType
  contract_type: DealContractType
  dispatching_date: string
  email: string
  parameters: DealContractParams[]
  payment_terms: string
  phone: string
  price: string
  product: Product
  product_purpose: ProductPurpose
  quantity: string | number
  season: string
  seller_company: CompanyShort
  transfer_address: string
  has_vat: boolean
  parameters_comment: string
}
export enum LogisticsRateSource {
  routes = 'routes',
  rates = 'rates',
}

//TODO: чекнуть DealBase и раскидать ключи которые не входят в общие(деталка и список)
type DealBase = {
  id: string
  deal_type: DealType
  start_date: string | null
  end_date: string | null
  status: DealStatusTypes
  status_label?: string
  closed_at: string | null
  closed_reason: ClosedReason | null
  export_contract_id: string | null
  export_contract_price: string
  fixed_margin_by_export_contract: boolean
  quantity: number
  quantity_on_contract_signed: number
  margin: number
  margin_level: MarginLevel
  net_margin: number
  distance: number
  parameterized_distance: number
  purchase_price: number
  sale_price: number
  tax_from_exw?: number
  tax_from_logistics_price?: number
  price_logistics_per_ton?: number
  price_logistics_per_bag?: number
  price_with_logistics_per_ton: number
  price_logistics: number
  logistics_rate_source: LogisticsRateSource | null
  price_carrier: number
  product: Product
  sale_contract_request: DealContract
  sale_contract: BidContract | null
  purchase_contract: BidContract | null
  purchase_specification: PurchaseSpecification
  specifications_for_purchase: SpecificationsForPurchase[]
  fetchPurchaseSpecificationProgress: Progress
  fetchPurchaseSpecificationError: NetworkError | null
  bindingSpecificationProgress: Progress
  bindSpecificationError?: NetworkError | null
  created?: string
  target_margin_percent: number
  has_samples: boolean
  has_instructions: boolean
  modified?: string
  gmv?: number
  price_with_logistics?: number
  price_exw: number
  price_cpt: number
  paid_sum: number
  payment_delay_days: number
  delivered_quantity?: number
  loaded_quantity?: number
  margin_percent: number
  margin_per_kg: number
  is_private: boolean
  actual_margin: number
  margin_on_way: number
  actual_margin_per_kg: number
  actual_margin_percent: number
  source: DealSource
  who_responded: WhoResponded | null
  highlight_price: HighlightPrice | null
  commission?: number
  fixed_margin?: number
  price_logistics_ton_per_km: number
  price_logistics_bag_per_km: number
  available_statuses: string[]
  approval_status: string
  days_for_export: number
  margin_on_contract_signed: number | null
  margin_percent_on_contract_signed: number | null
  purchase_price_on_contract_signed: number
  sale_price_on_contract_signed: number
  price_logistics_on_contract_signed: number
  executors: Executors | null
  is_approved_by_commercial_director: boolean
  is_approved_by_logistician: boolean
  margin_confirmed: boolean
  can_request_documents: boolean
  delivery_side: DeliverySide
  documents_request: {
    id: string
    expected_complete_date: DateISO
    received_ds: boolean
    received_conclusion: boolean
    received_vet: boolean
    received_sdiz: boolean
    product_purpose: ProductPurpose
    request_ds: boolean
    request_quarantine: boolean
    request_sdiz: boolean
    request_vet: boolean
  } | null
  without_documents_request: boolean
  without_purchase_specification: boolean
  quantity_paid_for: number
  logistics_request_id: number | null
  mark_purchase_specification: boolean
  mark_documents_request: boolean
  has_sale_contract_request: boolean
  can_move_next_status: boolean
  season?: Season
  revenue: number
  logistics_costs: number
  purchase_costs: number
  non_operating_costs: number
  is_position: boolean
  trip_count_norm: {
    id: string
    loading_count: number
    unloading_count: number
  } | null
  actual_contribution_margin: number | null
  actual_contribution_margin_rate: number | null
  //Hints
  margin_percent_formula: string
  margin_percent_tooltip: string
}

export type Deal = DealBase & {
  sale_bid: Bid
  purchase_bid: Bid
  estimated_seller_basis: number
  estimated_seller_basis_delivered: number
  next_status_validation: Record<string, VaidationRecord>
  seller_broker: BrokerData | null
  customer_broker: BrokerData | null
  has_warehouse_movements: boolean

  turnover_days: number
  actual_turnover_days: number
  seller_actual_turnover_days: number
  customer_actual_turnover_days: number

  actual_contribution_margin_per_unit: number

  contribution_margin: number
  contribution_margin_per_unit: number
  contribution_margin_rate: number
  actual_credit_cost_per_unit: number
  actual_credit_costs: number
  actual_credit_rate: number
  credit_rate: number
  credit_costs: number
  cost_per_unit: number

  //Hints
  estimated_seller_basis_formula: string
  estimated_seller_basis_tooltip: string
  estimated_seller_basis_delivered_formula: string
  estimated_seller_basis_delivered_tooltip: string
  margin_formula: string
  margin_tooltip: string
  price_logistics_formula: string
  price_logistics_tooltip: string
  gmv_formula: string
  gmv_tooltip: string
  margin_per_kg_formula: string
  margin_per_kg_tooltip: string
  price_logistics_ton_per_km_formula: string
  price_logistics_ton_per_km_tooltip: string
}

export type ListDeal = DealBase & {
  sale_bid: ListDealBid
  purchase_bid: ListDealBid
  margin_remaining: number
  margin_remaining_percent: number
  actual_turnover_days_are_filled: boolean

  //Hints
  margin_remaining_formula: string
  margin_remaining_tooltip: string
  margin_on_way_formula: string
  margin_on_way_tooltip: string
  margin_on_contract_signed_formula: string
  margin_on_contract_signed_tooltip: string
  margin_percent_on_contract_signed_formula: string
  margin_percent_on_contract_signed_tooltip: string
  actual_contribution_margin_formula: string
  actual_contribution_margin_tooltip: string
}

export type DealDTO = {
  quantity: number
  price_logistics: number
  distance: number
  deal_type: DealType
  sale_price: number
  purchase_price: number
  /** @deprecated */
  price_exw: number
  /** @deprecated */
  price_cpt: number
  paid_sum: number
  fixed_margin?: number
  days_for_export?: number
}

export type DealShipment = {
  id: string
  delivered_at: string
  quantity: number
  price_exw: number
  price_cpt: number
  price_logistics: number
  gmv: number
  margin: number
  margin_percent: number
  lost_amount: number
  timeout_amount: number
}

export type BrazilDealShipment = DealShipment & {
  picked_up_at: string
  pick_up_quantity: number
}

export enum DealCondition {
  open = 'open',
  closed = 'closed',
}

export type DealsBidDto = {
  quantity: number
  price: number
}

export type SpecificationsForPurchase = {
  id: string
  number: string
  signed_date: string
  company: Company
  contract: Contract
  owner: User
  address: Address
  product: Product
  price: number
  quantity: number
  quantity_shipped: number
  quantity_left: number
  deadline: string
  status: string
}

export type PurchaseSpecification = {
  contract?: string | null
  id: string
  number: string
  signed_date: string
  status: string
}

export type DealShipmentForm = Omit<
  DealShipment,
  'id' | 'gmv' | 'margin' | 'margin_percent' | 'timeout_amount' | 'lost_amount'
>

export enum DealStatusTypes {
  DRAFT = 'draft',
  NEW = 'new',
  IN_PROGRESS = 'in_progress',
  CUSTOMER_NEGOTIATIONS = 'customer_negotiations',
  AGREED = 'agreed',
  CONTRACT_SIGNED = 'contract_signed',
  PAYMENT_SHIPMENT = 'payment_shipment',
  PICK_UP_COMPLETE = 'pick_up_complete',
  SHIPMENT_COMPLETE = 'shipment_complete',
  DOCUMENTS_ACCEPTED = 'documents_accepted',
  PAYMENT_COMPLETE = 'payment_complete',
  EXPECTED_MARGIN = 'expected_margin',
  CONFIRMED_BATCH = 'confirmed_batch',
  BEST_BATCH = 'best_batch',
}

export const EditableDealStatuses = [
  DealStatusTypes.NEW,
  DealStatusTypes.IN_PROGRESS,
  DealStatusTypes.AGREED,
  DealStatusTypes.CONTRACT_SIGNED,
]

export type DealStatus = {
  slug: DealStatusTypes
  label: string
}

export type Margins = {
  documents_accepted: number
  shipment_complete: number
  payment_shipment: number
  payment_complete: number
  contract_signed: number
  expected_margin: number
  in_progress: number
  agreed: number
  new: number
}
export type MarginsforDeals = {
  weighted_average_margins: Margins
  margins: Margins
}

export type SellerSpec = {
  id: string
  price: string
  files?: RespFile[]
}

export enum DealContractType {
  agroclub = 'agroclub',
  farmer = 'farmer',
}

export enum FarmerLoadWorkTypes {
  sample = 'sample',
  loading = 'loading',
  documents = 'documents',
}
export enum ShipLoadWorkTypes {
  sample = 'sample',
  unloading = 'unloading',
  ship_loading = 'ship_loading',
}

type LoadBase = {
  id: string
  regional: Executor
  quantity: number
  load_date: DateISO
}

export type ShipLoad = LoadBase & {
  work_type: ShipLoadWorkTypes
}
export type FarmerLoad = LoadBase & {
  work_type: FarmerLoadWorkTypes
}
