import { useTranslation } from 'react-i18next'
import { t } from 'i18next'
import { REACT_APP_INSTANCE_ID } from 'env'
import { Bid, BidPrice, BidSources, USBid } from 'modules/domain/bid/types'
import { getPriceTypeLabel, isBidWithPricePeriods } from 'modules/domain/bid/utils'

import { CellRenders } from 'views/components/Table/renderers'

import { labelTarif } from 'modules/utils/numbers/formatTarif'
import { labelDistance } from 'modules/utils/numbers/formatDistance'
import { DatePeriodFormatted } from 'views/components/DatePeriodFormatted'

import { CompanyCell, ProductCell } from './styled'
import { DASH, EXPANDABLE_COLUMN_WIDTH } from 'modules/constants'
import { labelQuantity } from 'modules/utils/numbers/formatQuantity'
import { BidStatusTag } from 'views/components/Tags'
import { TruncatedValue } from 'views/components/TruncatedValue/TruncatedValue'
import { TableTextLink } from 'views/ui/TableTextLink/TableTextLink'
import { generatePath } from 'react-router-dom'
import UserRoutes from 'views/pages/User/routes'

const defaultWidthOpt = {
  product: 202,
  status: 118,
  volumeTon: 100,
  volumeBags: 120,
  exw: 108,
  exwTax: 146,
  cpt: 108,
  address: 258,
  distance: 144,
  company: 240,
  samples: 68,
  team: 148,
  created: 112,
  created_by: 200,
  modified: 112,
  cashPrice: 147,
  basis: 110,
  futuresPrice: 155,
  symbol: 92,
  fobBasis: 143,
  deliveryBasis: 175,
}

const widthOptions = {
  br: {
    product: 200,
    status: 98,
    volumeTon: 120,
    exw: 130,
    cpt: 130,
    address: 192,
    distance: 124,
    company: 220,
    created: 110,
    modified: 110,
  },
  us: {
    product: 180,
    status: 94,
    firm: 60,
    volumeTon: 110,
    distance: 120,
    exw: 108,
    salePriceDelivered: 139,
    basis: 113,
    address: 240,
    created: 110,
    cpt: 130,
  },
}

const overrideWidth = widthOptions[REACT_APP_INSTANCE_ID] || {}

const columnWidths = { ...defaultWidthOpt, ...overrideWidth }

const minPeriodPriceFactory = (key: 'price_cpt' | 'price_exw') => (_, bid: Bid) => {
  if (!isBidWithPricePeriods(bid)) {
    return CellRenders.price(bid[key])
  }
  const prices = bid.price_periods.map((period) => period[key] || 0)
  const minPrice = CellRenders.price(Math.min(...prices))
  if (!minPrice) {
    return null
  }
  return bid.price_periods.length > 1 ? `${t('common:from')} ${minPrice}` : `${minPrice}`
}

export const useBidColumns = () => {
  const { t } = useTranslation('bid')
  return {
    product: {
      title: t('list.tableHeaders.product'),
      width: columnWidths.product,
      dataIndex: 'product',
      render: ({ title }, { parameters_description, id, status, source, season }) => (
        <ProductCell
          id={id}
          title={title}
          status={status}
          parameters_description={parameters_description.split('\n')[0]}
          source={source}
          season={season?.name}
        />
      ),
    },
    status: {
      title: t('list.tableHeaders.status'),
      width: columnWidths.status,
      dataIndex: 'status',
      render: (status_, bid: Bid) => <BidStatusTag bid={bid} />,
    },
    firm: {
      title: t('list.tableHeaders.firm'),
      width: columnWidths.firm,
      dataIndex: 'is_firm',
      render: (is_firm) => <>{is_firm ? t('common:yes') : t('common:no')}</>,
    },
    quantity: {
      title: labelQuantity(),
      dataIndex: 'quantity',
      width: columnWidths.volumeTon,
      align: 'right',
      sorter: true,
      render: CellRenders.number,
    },
    price_exw: {
      title: labelTarif(getPriceTypeLabel(BidPrice.EXW, t)),
      dataIndex: 'price_exw',
      width: columnWidths.exw,
      align: 'right',
      sorter: true,
      render: minPeriodPriceFactory('price_exw'),
    },
    price_cpt: {
      title: labelTarif(getPriceTypeLabel(BidPrice.CPT, t)),
      width: columnWidths.cpt,
      dataIndex: 'price_cpt',
      align: 'right',
      sorter: true,
      render: minPeriodPriceFactory('price_cpt'),
    },
    address: {
      title: t('list.tableHeaders.address'),
      autoWidth: columnWidths.address,
      dataIndex: 'address',
      render: ({ address }) => address,
    },
    distance: {
      title: labelDistance(t('common:remoteness')),
      width: columnWidths.distance,
      dataIndex: 'distance',
      align: 'right',
      sorter: true,
      render: CellRenders.number,
    },
    company: {
      title: t('list.tableHeaders.company'),
      width: columnWidths.company,
      dataIndex: 'company',
      render: ({ id, name }) => <CompanyCell id={id} name={name} />,
    },
    has_samples: {
      title: t('samples:samples'),
      width: columnWidths.samples,
      dataIndex: 'has_samples',
      render: CellRenders.haveOrNot,
    },
    owner: {
      title: t('list.tableHeaders.team'),
      width: columnWidths.team,
      dataIndex: 'owner',
      render: ({ profile }) => profile?.team?.name || null,
    },
    created: {
      title: t('list.tableHeaders.created'),
      width: columnWidths.created,
      dataIndex: 'created',
      sorter: true,
      render: CellRenders.date,
    },
    created_by: {
      title: t('list.tableHeaders.created_by'),
      width: columnWidths.created_by,
      dataIndex: 'created_by',
      render: (user, data) =>
        user?.id && (
          <TruncatedValue hint={data.source === BidSources.BARCHART ? '' : user?.full_name}>
            <TableTextLink id="bidCreatedBy" to={generatePath(UserRoutes.Details, { id: user.id })}>
              {data.source === BidSources.BARCHART ? t('common:systemUser') : user?.full_name || DASH}
            </TableTextLink>
          </TruncatedValue>
        ),
    },
    modified: {
      title: t('list.tableHeaders.modified'),
      width: columnWidths.modified,
      dataIndex: 'modified',
      sorter: true,
      render: CellRenders.date,
    },
    tax_from_exw: {
      title: labelTarif(t('common:tax')),
      dataIndex: 'tax_from_exw',
      width: columnWidths.exwTax,
      align: 'right',
      sorter: true,
      render: CellRenders.price,
    },
    price_period: {
      title: t('common:period'),
      width: columnWidths.product + columnWidths.status,
      sorter: false,
      render: (_, period) => <DatePeriodFormatted period={period} />,
    },
    empty_expandable: { width: EXPANDABLE_COLUMN_WIDTH, render: () => ' ' },
    empty: { render: () => ' ' },
    basis: {
      title: labelTarif(t('bid:basis')),
      dataIndex: 'basis',
      width: columnWidths.basis,
      align: 'right',
      sorter: true,
      render: CellRenders.price,
    },
    futuresPrice: {
      title: labelTarif(t('bid:futuresPrice')),
      // dataIndex: 'price_period',
      width: columnWidths.futuresPrice,
      align: 'right',
      sorter: true,
      render: (_, bid: USBid) => (bid.price_periods?.[0] ? CellRenders.price(bid.futures_price) : undefined),
    },
    cashPrice: {
      title: labelTarif(t('bid:cashPrice')),
      dataIndex: 'price',
      width: columnWidths.cashPrice,
      align: 'right',
      render: CellRenders.price,
    },
    salePriceDelivered: {
      title: labelTarif(getPriceTypeLabel(BidPrice.CPT, t)),
      dataIndex: 'sale_price_delivered',
      width: columnWidths.cpt,
      align: 'right',
      render: CellRenders.price,
    },
    symbol: {
      title: t('bid:symbol'),
      dataIndex: 'symbol',
      width: columnWidths.symbol,
    },
    fobBasis: {
      title: labelTarif(`${t('common:fob')} ${t('basis')}`),
      dataIndex: 'price_exw_basis',
      width: columnWidths.fobBasis,
      align: 'right',
      render: CellRenders.price,
    },
    deliveryBasis: {
      title: labelTarif(`${t('common:delivered')} ${t('basis')}`),
      dataIndex: 'sale_price_delivered_basis',
      width: columnWidths.deliveryBasis,
      align: 'right',
      render: CellRenders.price,
    },
  }
}
