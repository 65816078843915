import { SharedModalContent, SharedModalInputs, SharedModalTitle } from 'views/components/SharedModal'
import { FormGroup, Input, Select } from '@agro-club/agroclub-shared'
import { removePropertyNamePrefix, replaceErrorsDotNotation } from 'modules/utils/helpers'
import { FormPatronymicName } from 'views/components/form/FormPatronymicName'
import { FormPhoneInput } from 'views/components/form/FormPhoneInput'
import { FormLegalType } from 'views/components/form/FormLegalType'
import { FormFirstName } from 'views/components/form/FormFirstName'
import { ProfileTypeForAddUser, User } from 'modules/domain/user/types'
import { FormLastName } from 'views/components/form/FormLastName'
import { FormCompany } from 'views/components/form/FormCompany'
import { FormMap } from 'views/components/form/FormMap'
import { FormTeam } from 'views/components/form/FormTeam'
import { ShortFormControl } from 'views/styled/common'
import { FC, useEffect, useMemo } from 'react'
import { apiClient } from 'modules/utils/httpClient'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import { useFormik } from 'formik'
import env, { isBrazil } from 'env'
import ASharedModal from 'views/components/SharedModal/ASharedModal'
import { AnalyticsPlaces } from '../analyticsPlaces'
import ADefaultModalFooter from 'views/components/DefaultModalFooter/ADefaultModalFooter'
import { useAFormHandler } from 'analytics/hooks'
import { TFunction } from 'i18next'

interface Props {
  onSuccess: (user: User) => void
  onClose: () => void
}

type Address = {
  geo_object: any
  address: string
  latitude: number
  longitude: number
}

export type Fields = {
  recommended_margin_percent: string | number | null
  credit_limit: string | number | null
  legal_type?: string | null
  profile_type: string | null
  addresses?: Address[] | []
  company?: string | null
  patronymic_name: string
  phone?: string | null
  email: string | null
  team: string | null
  first_name: string
  last_name: string
  title?: string
  inn?: string
  is_mobile_phone: boolean
}

const getAvailableProfileType = (t: TFunction) => {
  let profileTypes = Object.keys(ProfileTypeForAddUser)

  if (isBrazil) {
    profileTypes = profileTypes.filter((pt) => pt !== ProfileTypeForAddUser.carrier)
  }

  return profileTypes.map((status) => ({ value: status, label: t(`form.profile_type.${status}`) }))
}

export const AddUser: FC<Props> = ({ onClose, onSuccess }) => {
  const { t } = useTranslation('user')
  const { formProgress, formHandler } = useAFormHandler()
  const currency = env.REACT_APP_CURRENCY_SYMBOL

  const typeUsers = useMemo(() => getAvailableProfileType(t), [t])

  const handlerMap = (data) => {
    if (!data.coords) {
      formik.setFieldValue('addresses', [])
      formik.setFieldValue('legal_type', undefined)
      return
    }
    formik.setFieldValue('addresses', [
      {
        geo_object: data.geoObject,
        address: data.address,
        latitude: data.coords && data.coords[0],
        longitude: data.coords && data.coords[1],
      },
    ])
  }

  const formik = useFormik<Fields>({
    initialValues: {
      recommended_margin_percent: null,
      profile_type: null,
      patronymic_name: '',
      credit_limit: null,
      addresses: [],
      first_name: '',
      last_name: '',
      company: null,
      legal_type: null,
      phone: '',
      is_mobile_phone: false,
      email: '',
      team: '',
      title: '',
    },

    onSubmit: formHandler(
      async () => {
        const addresses = formik.values.addresses?.length
          ? [
              {
                ...formik.values.addresses[0],
                legal_type: formik.values.legal_type || undefined,
                title: formik.values.title || undefined,
              },
            ]
          : []

        const data = {
          email: formik.values.email,
          phone: formik.values.phone,
          is_mobile_phone: formik.values.is_mobile_phone,
          addresses,
          profile: {
            recommended_margin_percent: formik.values.recommended_margin_percent
              ? formik.values.recommended_margin_percent
              : null,
            credit_limit: formik.values.credit_limit ? formik.values.credit_limit : null,
            patronymic_name: formik.values.patronymic_name,

            profile_type: formik.values.profile_type,
            first_name: formik.values.first_name,
            last_name: formik.values.last_name,
            company: formik.values.company,
            team: formik.values.team,
          },
        }

        return await apiClient.post(endpoints.users(), data)
      },
      {
        onSuccess: (user: User) => {
          onSuccess(user)
          onClose()
        },
        onError: (error) => {
          const { errors } = error
          const formattedErrors = replaceErrorsDotNotation(errors)
          formik.setErrors(removePropertyNamePrefix(formattedErrors, /profile_/))
        },
      },
    ),
  })
  const inactiveInput = !formik.values.addresses?.length

  const filterForLegalType = useMemo(
    () => (formik.values.profile_type ? { profile_type: formik.values.profile_type } : null),
    [formik.values.profile_type],
  )

  useEffect(() => {
    formik.setFieldValue('legal_type', undefined)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.profile_type])

  return (
    <ASharedModal id={AnalyticsPlaces.ADD_MODAL} size="medium" onClose={onClose}>
      <SharedModalTitle>{t('form.new_user')}</SharedModalTitle>
      <SharedModalContent>
        <SharedModalInputs>
          <ShortFormControl>
            <FormGroup error={formik.errors.profile_type} label={t('form.fields.profile_type')} required>
              <Select
                onChange={(value) => formik.setFieldValue('profile_type', value)}
                status={!!formik.errors.profile_type ? 'error' : ''}
                placeholder={t('form.profileTypePlaceholder')}
                value={formik.values.profile_type}
                options={typeUsers}
                showSearch={false}
                allowClear={false}
                fullWidth
              />
            </FormGroup>
          </ShortFormControl>
          <FormLastName formik={formik} />
          <FormFirstName formik={formik} />
          <FormPatronymicName formik={formik} />
          <FormPhoneInput formik={formik} />
          <FormGroup label={t('form.fields.email')} error={formik.errors.email}>
            <Input
              placeholder={t('form.email_placeholder')}
              {...formik.getFieldProps('email')}
              invalid={!!formik.errors.email}
            />
          </FormGroup>
          <FormMap handlerMap={handlerMap} formik={formik} />
          <FormGroup label={t('form.fields.title')} error={formik.errors.title}>
            <Input {...formik.getFieldProps('title')} invalid={!!formik.errors.title} />
          </FormGroup>
          {formik.values.profile_type !== ProfileTypeForAddUser.carrier && (
            <FormLegalType
              endpoint={endpoints.legalTypes()}
              filter={filterForLegalType}
              showHelpText={inactiveInput}
              disabled={inactiveInput}
              formik={formik}
            />
          )}

          <FormCompany formik={formik} showHelpText />
          <ShortFormControl>
            <FormTeam formik={formik} />
            <FormGroup
              label={t('form.fields.recommendedMarginPercent')}
              error={formik.errors.recommended_margin_percent}
            >
              <Input
                {...formik.getFieldProps('recommended_margin_percent')}
                invalid={!!formik.errors.recommended_margin_percent}
                type="number"
              />
            </FormGroup>
            <FormGroup label={t('form.fields.justCreditLimit', { currency })} error={formik.errors.credit_limit}>
              <Input
                {...formik.getFieldProps('credit_limit')}
                invalid={!!formik.errors.credit_limit}
                type="number"
                min={0}
              />
            </FormGroup>
          </ShortFormControl>
        </SharedModalInputs>
      </SharedModalContent>
      <ADefaultModalFooter
        progress={formProgress}
        confirmButtonText={t('common:save')}
        onClose={onClose}
        onConfirm={() => formik.handleSubmit()}
      />
    </ASharedModal>
  )
}
