import { ReactNode, useRef } from 'react'
import styled from 'styled-components'
import { Progress } from '@agro-club/agroclub-shared'
import { AButton } from 'views/components/Analytics'
import { SVGIcon } from '@agro-club/agroclub-shared/dist/cjs/ui/buttons/Buttons/types'

type ButtonSize = 'small' | 'medium' | 'big' | 'biggest' | 'bigRound'

const FileInput = styled.input`
  display: none;
`

export interface UploadFileButtonProps {
  progress?: Progress
  onUpload(files: File | File[]): void
  accept?: string
  size?: ButtonSize
  icon?: SVGIcon
  text?: ReactNode
  multiple?: boolean
}

export const InternalUploadFileButton = ({
  progress,
  onUpload,
  accept,
  text,
  icon,
  size = 'medium',
  multiple,
}: UploadFileButtonProps) => {
  const ref = useRef<HTMLInputElement>(null)

  const addFile = () => {
    ref.current?.click()
  }

  const onChange = (event): void => {
    const files = multiple ? [...event.target.files] : event.target.files[0]

    onUpload(files)
  }

  return (
    <div>
      <AButton id="addFile" size={size} intent="secondary" Icon={icon} onClick={addFile} progress={progress}>
        {text}
      </AButton>
      <FileInput multiple={multiple} type="file" ref={ref} onChange={onChange} accept={accept} />
    </div>
  )
}
