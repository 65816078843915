import { SummaryThereIsNo } from 'views/components/AntCollapseHeader/SummaryThereIsNo'
import { SummaryComments } from 'views/components/AntCollapseHeader/SummaryComments'
import AntCollapseHeader from 'views/components/AntCollapseHeader/AntCollapseHeader'
import { AntCollapse, Panel, Progress } from '@agro-club/agroclub-shared'
import { DocumentFile, ListRequestParams } from 'modules/domain/types'
import { HistoryTable } from '../../components/History/HistoryTable'
import AComments from 'views/components/Comments/AComments'
import { CommentType } from 'modules/domain/comment/types'
import { refetchFunc } from 'modules/domain/common/hooks'
import { Export } from 'modules/domain/export/types'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import { FC, useMemo, useState } from 'react'
import { Documents } from './Documents'

type Props = {
  setListRequestParams: (v: ListRequestParams) => void
  refetchAll: refetchFunc
  listRequestParams: ListRequestParams
  exportDocuments: DocumentFile[]
  historyProgress: Progress
  historyTotal: number
  historyData: any[]
  exportData: Export
}

export const ExportAccordion: FC<Props> = ({
  setListRequestParams,
  listRequestParams,
  historyProgress,
  exportDocuments,
  historyTotal,
  historyData,
  exportData,
  refetchAll,
}) => {
  const { t } = useTranslation('export')

  const [commentsSummary, setCommentsSummary] = useState<string>('')
  const options = useMemo<{ title: string; value: string; isShow?: boolean }[]>(
    () =>
      [
        { value: 'comments', title: t('comments'), isShow: true },
        { value: 'history', title: t('history'), isShow: true },
        { value: 'documents', title: t('documents'), isShow: true },
      ].filter((option) => option.isShow),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t],
  )

  const [activeKey, setActiveKey] = useState<string | string[]>(options[0].value)

  const components = {
    comments: (
      <AComments
        url={endpoints.exportComments(exportData.id)}
        popSummary={setCommentsSummary}
        type={CommentType.export}
        id={'exportComment'}
        maxHeight={400}
      />
    ),

    history: (
      <HistoryTable
        setListRequestParams={setListRequestParams}
        listRequestParams={listRequestParams}
        historyProgress={historyProgress}
        historyTotal={historyTotal}
        historyData={historyData}
      />
    ),
    documents: (
      <Documents exportId={exportData.id} exportDocuments={exportDocuments} exportDocumentsRefetch={refetchAll} />
    ),
  }

  const summary = {
    history: <SummaryThereIsNo hasData={!!historyData.length} />,
    comments: <SummaryComments summary={commentsSummary} />,
    documents: <SummaryThereIsNo hasData={exportDocuments.length > 0} />,
  }

  return (
    <div>
      <AntCollapse accordion bordered={false} defaultActiveKey={options[0].value} onChange={(key) => setActiveKey(key)}>
        {options.map((option) => (
          <Panel
            forceRender
            header={
              <AntCollapseHeader title={option.title} summary={activeKey !== option.value && summary[option.value]} />
            }
            key={option.value}
          >
            {components[option.value]}
          </Panel>
        ))}
      </AntCollapse>
    </div>
  )
}
