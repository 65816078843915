import { useState, useEffect } from 'react'
import { DocumentFile } from 'modules/domain/types'
import { useAProgress } from 'hooks/useAProgress'
import * as managers from 'modules/domain/common/managers'
import { endpoints } from 'modules/endpoints'
import { ExportDocumentType } from 'modules/domain/export/types'
import { FormikProps } from 'formik'

export const useFileHandler = (formik: FormikProps<any>, documentField: string, documentType: ExportDocumentType) => {
  const [fileResponse, setFileResponse] = useState<DocumentFile[]>(formik.values[documentField])

  const [uploadProgress, upload] = useAProgress<DocumentFile>((file) => managers.uploadFile(endpoints.files(), file), {
    eventName: 'uploadDocument',
    onSuccess: (response) => setFileResponse([...fileResponse, response]),
  })

  const deleteFile = (id: string) => {
    setFileResponse(fileResponse.filter((file) => file.id !== id))
  }

  useEffect(() => {
    const docs = fileResponse.length ? fileResponse.map((file) => ({ ...file, document_type: documentType })) : []
    formik.setFieldValue(documentField, docs)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileResponse])

  return { fileResponse, uploadProgress, upload, deleteFile }
}
