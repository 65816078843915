import { type InstanceID, InstanceIDs } from 'modules/domain/types'
import { BrSiteSettings } from 'sitesSettings/BrSiteSettings'
import { RuSiteSettings } from 'sitesSettings/RuSiteSettings'
import { UsSiteSettings } from 'sitesSettings/UsSiteSettings'
import { SiteSettings } from 'sitesSettings/types'

export const DEVELOPMENT = process.env.NODE_ENV === 'development'

const getEnv = (key: string) => {
  if (DEVELOPMENT) {
    return process.env[key]
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return window[key]
}

export const REACT_APP_INSTANCE_ID = (getEnv('REACT_APP_INSTANCE_ID').toLowerCase().trim() ||
  InstanceIDs.RU) as InstanceID

export const isBrazil = REACT_APP_INSTANCE_ID == InstanceIDs.BR
export const isUS = REACT_APP_INSTANCE_ID == InstanceIDs.US
export const isRussia = REACT_APP_INSTANCE_ID == InstanceIDs.RU

export const isFuturesEnabled = isBrazil || isUS
export const isUserProductTagsEnabled = !isBrazil
export const isBidProductParamsEnabled = isRussia
export const isPriceTypeEnabled = !isRussia
export const isFactoringAvailable = isRussia
export const isLegacyBidCloningEnabled = isRussia
export const isBidContractsEnabled = isBrazil
export const isDealPartyChangeEnabled = isBrazil
export const isVisibleCredit = isRussia

export const dashboardRegionDetailedFilter = isBrazil || isUS

let settings: SiteSettings
if (REACT_APP_INSTANCE_ID === InstanceIDs.BR) {
  settings = BrSiteSettings
} else if (REACT_APP_INSTANCE_ID === InstanceIDs.US) {
  settings = UsSiteSettings
} else {
  settings = RuSiteSettings
}

export const REACT_APP_API_BASE_URL = getEnv('REACT_APP_API_BASE_URL') || settings.DEFAULT_API_BASE
export const REACT_APP_LOCALE = getEnv('REACT_APP_LOCALE') || settings.DEFAULT_APP_LOCALE
export const REACT_APP_CURRENCY_SYMBOL = settings.REACT_APP_CURRENCY_SYMBOL
export const REACT_APP_USE_GOOGLE_MAPS = settings.REACT_APP_USE_GOOGLE_MAPS

export const SENTRY_DSN = getEnv('REACT_APP_SENTRY_DSN') || null
export const SEGMENT_WRITE_KEY = getEnv('REACT_APP_SEGMENT_WRITE_KEY') || ''
export const REACT_APP_YA_MAPS_API_KEY = getEnv('REACT_APP_YA_MAPS_API_KEY')
export const REACT_APP_YA_MAPS_SUGGEST_API_KEY = getEnv('REACT_APP_YA_MAPS_SUGGEST_API_KEY')
export const REACT_APP_GOOGLE_MAPS_API_KEY = getEnv('REACT_APP_GOOGLE_MAPS_API_KEY')
export const REACT_APP_CHATRA_API_KEY = getEnv('REACT_APP_CHATRA_API_KEY')
export const PUSHER_APP_KEY = getEnv('REACT_APP_PUSHER_APP_KEY') || ''
export const PUSHER_APP_CLUSTER = getEnv('REACT_APP_PUSHER_APP_CLUSTER') || ''
export const REACT_APP_GTM = getEnv('REACT_APP_GTM') || ''
export const REACT_APP_VERSION = getEnv('REACT_APP_VERSION') || ''

export default {
  SEGMENT_WRITE_KEY,
  REACT_APP_API_BASE_URL,
  SENTRY_DSN,
  DEVELOPMENT,
  REACT_APP_YA_MAPS_API_KEY,
  REACT_APP_YA_MAPS_SUGGEST_API_KEY,
  REACT_APP_LOCALE,
  REACT_APP_CURRENCY_SYMBOL,
  REACT_APP_USE_GOOGLE_MAPS,
  REACT_APP_GOOGLE_MAPS_API_KEY,
  REACT_APP_CHATRA_API_KEY,
  PUSHER_APP_KEY,
  PUSHER_APP_CLUSTER,
  REACT_APP_INSTANCE_ID,
  REACT_APP_GTM,
  REACT_APP_VERSION,
}

export { InstanceIDs }
