import { FormGroup, Input, Progress, defaultTheme } from '@agro-club/agroclub-shared'
import { labelQuantity } from 'modules/utils/numbers/formatQuantity'
import { KeyValue } from 'views/components/KeyValue/KeyValue'
import { labelTarif } from 'modules/utils/numbers/formatTarif'
import { refetchFunc } from 'modules/domain/common/hooks'
import { useNotification } from 'hooks/useNotification'
import { ListDeal } from 'modules/domain/deal/types'
import { apiClient } from 'modules/utils/httpClient'
import { AButton } from 'views/components/Analytics'
import { useAFormHandler } from 'analytics/hooks'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import styled from 'styled-components'
import { useFormik } from 'formik'
import { FC } from 'react'
import _ from 'lodash'

export const BlockForm = styled.div`
  transition: opacity ease-in 0.2s;
`
export const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px 0;
`
export const InputsWrapper = styled.div`
  background: ${defaultTheme.color.primary8a};
  border: 1px solid ${defaultTheme.color.secondaryHover};
  padding: 12px 0 12px 12px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px 0;
`

export const ShortInput = styled(Input)`
  width: 94px;
  input {
    padding: 5px 12px;
  }
`
export const Button = styled(AButton)`
  width: 100%;
`

type Props = {
  withExecutors?: boolean
  partyChanging?: boolean
  refetch: refetchFunc
  onCancel: () => void
  deal: ListDeal
}

export const BestBatchCardForm: FC<Props> = ({ onCancel, deal, refetch }) => {
  const { t } = useTranslation('deal')

  const notify = useNotification()

  const { formProgress, formHandler } = useAFormHandler('newBatch')

  const formik = useFormik<{ price_logistics: number; quantity: number }>({
    initialValues: {
      price_logistics: deal.price_logistics,
      quantity: deal.quantity,
    },
    enableReinitialize: true,

    onSubmit: formHandler(
      async () => {
        await apiClient.post(endpoints.dealConfirmBatch(deal.id), { ...formik.values })
      },

      {
        onSuccess: () => {
          refetch()
        },
        onError: (error) => {
          const { errors } = error
          formik.setErrors(errors)

          if (!_.isEmpty(errors)) {
            Object.values(errors)
              .flat()
              .forEach((message) => notify(Progress.ERROR, { title: message }))
          }
        },
      },
    ),
  })

  return (
    <BlockForm>
      <Form>
        <InputsWrapper>
          <KeyValue horizontal label={labelQuantity()}>
            <FormGroup error={formik.errors.quantity} required>
              <ShortInput {...formik.getFieldProps('quantity')} type="number" inputSize="small" />
            </FormGroup>
          </KeyValue>

          <KeyValue horizontal label={labelTarif(t('freightCost'))}>
            <FormGroup error={formik.errors.price_logistics} required>
              <ShortInput
                {...formik.getFieldProps('price_logistics')}
                invalid={!!formik.errors.price_logistics}
                inputSize="small"
              />
            </FormGroup>
          </KeyValue>
        </InputsWrapper>

        <Button
          onClick={() => formik.handleSubmit()}
          progress={formProgress}
          intent="primary"
          type="submit"
          size="medium"
          id="create"
        >
          {t('createBatch')}
        </Button>
        <Button
          intent="white"
          id="cancel"
          type="button"
          size="medium"
          onClick={(e) => {
            e.stopPropagation()
            onCancel()
          }}
        >
          {t('common:cancel')}
        </Button>
      </Form>
    </BlockForm>
  )
}
