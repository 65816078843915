import { useFormik } from 'formik'
import { FC } from 'react'
import { Company, CompanyDTO, Prepayment } from 'modules/domain/company/types'
import { CompanyRuDetailsModal } from './CompanyRuDetailsModal'
import { useAFormHandler } from 'analytics/hooks'
import { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'

type Props = {
  refreshCompanies: (silent: boolean) => void
  onClose: () => void
}

export const CompanyRuAdd: FC<Props> = ({ onClose, refreshCompanies }) => {
  const { formProgress, formHandler } = useAFormHandler()

  const formik = useFormik<CompanyDTO>({
    initialValues: {
      inn: null,
      name: null,
      prepayment: Prepayment.unknown,
      payment_delay_days: 0,
      vat: false,
      company_size: null,
    },
    enableReinitialize: true,
    onSubmit: formHandler(async () => await apiClient.post<Company>(endpoints.company(), formik.values), {
      onSuccess: async () => {
        await refreshCompanies(true)
        onClose()
      },
      onError: (error) => {
        const { errors } = error
        formik.setErrors(errors)
      },
    }),
  })

  return <CompanyRuDetailsModal progress={formProgress} onClose={onClose} formik={formik} showCompany />
}
