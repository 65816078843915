import PermissionCheck from 'views/components/PermissionCheck/PermissionCheck'
import { VerifiedTitle, Verified, WrapperIcon, VerifiedDate } from '../../styled'
import { Icons } from '@agro-club/agroclub-shared'
import { refetchFunc } from 'modules/domain/common/hooks'
import { User } from 'modules/domain/user/types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SecurityConfirmModal } from './ConfirmModals/SecurityConfirmModal'
import useFormatDate from 'hooks/useFormatDate'
import { isBrazil } from 'env'

type Props = {
  userRefetch: refetchFunc
  user: User
}

export const VerifiedBySecurity = ({ user, userRefetch }: Props) => {
  const { t } = useTranslation('user')
  const formatDate = useFormatDate()
  const [verifySecurityModal, setVerifySecurityModal] = useState(false)

  if (!user.profile) return null

  return (
    <>
      <PermissionCheck scope={['users.can_verify_as_security']}>
        <div>
          <Verified
            isActive={user.is_active}
            isVerified={user.profile.is_verified_by_security}
            onClick={() => setVerifySecurityModal(true)}
          >
            <div>
              <WrapperIcon>
                <Icons.IconDefend />
              </WrapperIcon>
              <VerifiedTitle>
                {t('verified')}
                <br />
                {t('is_verified_by_security')}
              </VerifiedTitle>
            </div>
          </Verified>
          {user.profile.verified_by_security_at && !isBrazil && (
            <VerifiedDate>{formatDate(user.profile.verified_by_security_at)}</VerifiedDate>
          )}
        </div>
      </PermissionCheck>

      {verifySecurityModal && (
        <SecurityConfirmModal onClose={() => setVerifySecurityModal(false)} refetch={userRefetch} userId={user.id} />
      )}
    </>
  )
}
