import FormSelectShared, { FormSelectSharedProps } from 'views/components/FormSelectShared'
import { AUploadFileButton } from 'views/components/UploadFileButton/AUploadFileButton'
import { ExportDocumentType } from 'modules/domain/export/types'
import { FileListComponent } from './InputWithFiles'
import { Icons } from '@agro-club/agroclub-shared'
import { useFileHandler } from './useFileHandler'
import { ExportFormFields } from './AddExport'
import styled from 'styled-components'
import { FormikProps } from 'formik'
import { FC } from 'react'

const Wrapper = styled.div`
  margin: 0 0 0 8px;
`
const WrapperList = styled.div`
  padding: 0 20px 0 210px;
`

type Props = {
  formik: FormikProps<ExportFormFields>
  documentType: ExportDocumentType
  documentField: string
} & FormSelectSharedProps

export const SelectWithFile: FC<Props> = ({ documentField, documentType, formik, ...props }) => {
  const { fileResponse, uploadProgress, upload, deleteFile } = useFileHandler(formik, documentField, documentType)

  return (
    <div>
      <FormSelectShared {...props} formik={formik}>
        <Wrapper>
          <AUploadFileButton
            eventName="uploadDocument"
            progress={uploadProgress}
            icon={Icons.IconClip}
            onUpload={upload}
            size="big"
            text=""
          />
        </Wrapper>
      </FormSelectShared>
      <WrapperList>
        <FileListComponent files={fileResponse} deleteFile={deleteFile} />
      </WrapperList>
    </div>
  )
}
