import { AUploadFileButton } from 'views/components/UploadFileButton/AUploadFileButton'
import { ExportDocumentType } from 'modules/domain/export/types'
import FormSelectShared from 'views/components/FormSelectShared'
import { FormGroup, Icons } from '@agro-club/agroclub-shared'
import { FileListComponent } from './InputWithFiles'
import { PriceUnitSelect } from '../PriceUnitSelect'
import { useFileHandler } from './useFileHandler'
import { ExportFormFields } from './AddExport'
import { ThreeFields } from './CommonFields'
import styled from 'styled-components'
import { FormikProps } from 'formik'
import { FC } from 'react'

type Props = {
  formik: FormikProps<ExportFormFields>
  documentType: ExportDocumentType
  documentField: string
  currencyField: string
  inputField: string
  fieldName: string
  endpoint: string
  label: string
}
const Wrapper = styled.div`
  margin: 0 0 0 8px;
`
const WrapperList = styled.div`
  padding-right: 20px;
`

export const ThreeFieldsWithFiles: FC<Props> = ({
  documentField,
  currencyField,
  documentType,
  inputField,
  fieldName,
  endpoint,
  formik,
  label,
}) => {
  const { fileResponse, uploadProgress, upload, deleteFile } = useFileHandler(formik, documentField, documentType)

  return (
    <FormGroup error={formik.errors[fieldName]} label={label} controlContainerStyle={{ padding: 0 }}>
      <ThreeFields>
        <FormSelectShared
          getLabel={(dto) => dto.name}
          fieldName={fieldName}
          endpoint={endpoint}
          showSearch={false}
          formik={formik}
          simpleApiFormat
          placeholder
          hideLabel
        >
          <Wrapper>
            <AUploadFileButton
              eventName="uploadDocument"
              progress={uploadProgress}
              icon={Icons.IconClip}
              onUpload={upload}
              size="big"
              text=""
            />
          </Wrapper>
        </FormSelectShared>
        <PriceUnitSelect currencyField={currencyField} inputField={inputField} formik={formik} />
      </ThreeFields>
      <WrapperList>
        <FileListComponent files={fileResponse} deleteFile={deleteFile} />
      </WrapperList>
    </FormGroup>
  )
}
