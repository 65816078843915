import { DealsTable } from '../../components/DealsTable'
import { Export } from 'modules/domain/export/types'
import { Card } from 'views/components/Card/Card'
import { CommonInfo } from './CommonInfo'
import styled from 'styled-components'
import { CostInfo } from './CostInfo'
import { FC } from 'react'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px 0;
  padding-bottom: 88px;
`

type Props = {
  exportData: Export
}

export const ExportLeftBlock: FC<Props> = ({ exportData }) => {
  return (
    <Wrapper>
      <Card.Container>
        <Card.Grid cols={2} maxColumnWidth={'550px'}>
          <CommonInfo exportData={exportData} />
          <CostInfo exportData={exportData} />
        </Card.Grid>
      </Card.Container>
      <DealsTable exportData={exportData} />
    </Wrapper>
  )
}
