import { BoardColumn, BoardContent, BoardHeader, BoardScrollWrapper } from 'views/components/Board/CardsBoard'
import { DealStatusTypes, ListDeal, MarginsforDeals } from 'modules/domain/deal/types'
import { PreloadCard, PreloadCount } from 'views/components/Board/Preload'
import { refetchFunc, useTableData } from 'modules/domain/common/hooks'
import { NoDataCard } from 'views/components/Board/NoDataCard'
import { Progress, Text } from '@agro-club/agroclub-shared'
import { ListRequestParams } from 'modules/domain/types'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { Count } from 'views/components/Board/styled'
import { DataMargin } from '../../helpers/DataMargin'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import { PreloadMargin } from '../Column'
import { InfoBlock } from './InfoBlock'
import { BestCard } from './BestCard'
import { FC, useState } from 'react'

type Props = {
  listRequestParams: ListRequestParams
  revenues?: MarginsforDeals
  margins?: MarginsforDeals
  progressMargin: Progress

  refetch: refetchFunc
}

export const BestColumn: FC<Props> = ({ listRequestParams, refetch, margins, revenues, progressMargin }) => {
  const { t } = useTranslation('deal')

  const [isShowBestBatchInfo, setIsShowBestBatchInfo] = useLocalStorage('isShowBestBatchInfo', true)
  const [selectedBestCard, setSelectedBestCard] = useState<string | null>(null)

  const { progress, data: deals = [] } = useTableData<ListDeal>(
    endpoints.dealActualWithStatus(DealStatusTypes.BEST_BATCH),
    listRequestParams,
    true,
  )

  const loading = progress === Progress.WORK

  const moneyBlock =
    !margins || progressMargin === Progress.WORK ? (
      PreloadMargin()
    ) : (
      <DataMargin status={DealStatusTypes.BEST_BATCH} margins={margins} revenues={revenues} />
    )

  return (
    <BoardColumn>
      <BoardHeader>
        <Text as="span" typography="titleH4">
          {t('bestBatch')}
          {loading ? <PreloadCount /> : <Count color={deals?.length ? 'orange' : 'grey'}>{deals?.length}</Count>}
        </Text>
        {moneyBlock}
      </BoardHeader>
      <BoardScrollWrapper topMargin={310}>
        <BoardContent>
          {loading ? (
            <PreloadCard />
          ) : deals.length ? (
            <>
              {isShowBestBatchInfo && <InfoBlock text={t('infoBest')} onClose={() => setIsShowBestBatchInfo(false)} />}
              {deals.map((deal) => {
                return (
                  <BestCard
                    selected={selectedBestCard === deal.id}
                    onClick={setSelectedBestCard}
                    refetch={refetch}
                    cardData={deal}
                    key={deal.id}
                    deal={deal}
                  />
                )
              })}
            </>
          ) : (
            <NoDataCard />
          )}
        </BoardContent>
      </BoardScrollWrapper>
    </BoardColumn>
  )
}
