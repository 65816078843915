import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Company, CompanyCommercial } from 'modules/domain/company/types'
import { CompanySize, User } from 'modules/domain/user/types'
import { KeyValue } from 'views/components/KeyValue/KeyValue'
import { CompanyUsers } from './CompanyUsers'
import { Card } from 'views/components/Card/Card'
import { formatBool } from 'helpers/formatBool'
import { EditCompanyControl } from '../EditCompanyControl'
import { isBrazil, isRussia, isUS } from 'env'

type Props = {
  company: Company
  companyUsers?: User[]
  refreshCompany: () => void
}

const VatComponent: FC<{ company: Company }> = ({ company }) => {
  const { t } = useTranslation('company')
  if (isBrazil) return null
  return <KeyValue label={t('form.fields.vat')}>{formatBool(company.vat, t('common:thereIs'))}</KeyValue>
}

const TurnoverComponent: FC<{ company: Company }> = ({ company }) => {
  const { t } = useTranslation('company')
  if (!isRussia || !company.affectable_turnover_days) return null
  return <KeyValue label={t('turnover')}>{company.affectable_turnover_days}</KeyValue>
}

const RestrictionSoyComponent: FC<{ company: Company }> = ({ company }) => {
  const { t } = useTranslation('company')
  if (!isBrazil) return null

  const soyRestriction = company.soy_restriction ? t('form.soyMoratorium.yes') : t('form.soyMoratorium.no')

  return <KeyValue label={t('soyRestriction')}>{soyRestriction}</KeyValue>
}

const CompanySizeComponent: FC<{ company: Company }> = ({ company }) => {
  const { t } = useTranslation('company')
  if (!isRussia) return null
  const sizeMap = { [CompanySize.big_company]: t('large'), [CompanySize.small_company]: t('small') }

  return (
    <KeyValue label={t('typeCompany')}>
      {company?.company_size ? sizeMap[company.company_size] : t('notSpecified')}
    </KeyValue>
  )
}

const CompanyCommercialComponent: FC<{ company: Company }> = ({ company }) => {
  const { t } = useTranslation('company')
  if (!isUS) return null

  const commercial = () => {
    if (company.commercial === CompanyCommercial.unknown) {
      return t('notSpecified')
    }
    return company.commercial === CompanyCommercial.yes ? t('yes') : t('no')
  }
  return <KeyValue label={t('commercial')}>{commercial()}</KeyValue>
}

export const CompanyGeneral: FC<Props> = ({ company, companyUsers, refreshCompany }) => {
  const { t } = useTranslation('company')
  const [companyEditModalOpen, setCompanyEditModalOpen] = useState(false)

  const vat = <VatComponent company={company} />

  return (
    <>
      <Card.Container>
        <Card.GapableContent>
          <Card.Title>{t('commonInfo')}</Card.Title>
          <Card.Container bordered>
            <Card.Grid cols={!!vat ? 3 : 4} columnGap={16} maxColumnWidth="auto">
              <VatComponent company={company} />
              <KeyValue label={t('form.fields.prepayment')}>
                {t(`form.prepaymentValues.${company.prepayment}`)}
              </KeyValue>
              <TurnoverComponent company={company} />
              <KeyValue label={t('form.fields.payment_delay_days')}>{company.payment_delay_days}</KeyValue>
              <CompanySizeComponent company={company} />
              <RestrictionSoyComponent company={company} />
              <CompanyCommercialComponent company={company} />
            </Card.Grid>
            <Card.EditButton onClick={() => setCompanyEditModalOpen(true)} />
          </Card.Container>
        </Card.GapableContent>
        {!!companyUsers?.length && (
          <>
            <CompanyUsers users={companyUsers} />
          </>
        )}
      </Card.Container>

      {companyEditModalOpen && (
        <EditCompanyControl
          setCompanyEditModalOpen={setCompanyEditModalOpen}
          refreshCompany={refreshCompany}
          company={company}
        />
      )}
    </>
  )
}
