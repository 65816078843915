import { isBrazil, isRussia, isUS } from 'env'
import { DealStatusTypes, ListDeal } from 'modules/domain/deal/types'
import { ContractTag } from 'views/pages/Deal/DealDetail/DealTags/ContractTag'
import { isStatusAfter } from '../helpers'
import { DirectorTag } from './DirectorTag'
import { DocumentsTag } from './DocumentsTag'
import { InstructionsTag } from './InstructionsTag'
import { LogisticsTag } from './LogisticsTag'
import { PositionTag } from './PositionTag'
import { SampleTag } from './SampleTag'
import { SpecificationTag } from './SpecificationTag'
import { Tag1C } from './Tag1C'
import { ContributionMarginTag } from './ContributionMarginTag'

interface tagDataItem {
  render: (deal: ListDeal, key: any) => JSX.Element
  isVisible: (deal: ListDeal) => boolean
}

export const useTags = (): tagDataItem[] => {
  const tags = [
    {
      render: (deal) => <PositionTag deal={deal} />,
      isVisible: () => isUS,
    },
    {
      render: (deal, key) => <InstructionsTag deal={deal} key={key} />,
      isVisible: (deal: ListDeal) => isBrazil && isStatusAfter(deal.status, DealStatusTypes.CONTRACT_SIGNED),
    },
    {
      render: (deal, key) => <SampleTag deal={deal} key={key} />,
      isVisible: (deal: ListDeal) =>
        (!isBrazil && deal.status === DealStatusTypes.IN_PROGRESS) ||
        deal.status === DealStatusTypes.CUSTOMER_NEGOTIATIONS,
    },
    {
      render: (deal, key) => <DirectorTag deal={deal} key={key} />,
      isVisible: (deal: ListDeal) => isRussia && deal.status === DealStatusTypes.AGREED,
    },
    {
      render: (deal, key) => <DocumentsTag deal={deal} key={key} />,
      isVisible: (deal: ListDeal) => isRussia && deal.status === DealStatusTypes.CONTRACT_SIGNED,
    },
    {
      render: (deal, key) => <LogisticsTag deal={deal} key={key} />,
      isVisible: (deal: ListDeal) =>
        (deal.status === DealStatusTypes.AGREED || deal.status === DealStatusTypes.CUSTOMER_NEGOTIATIONS) && isRussia,
    },
    {
      render: (deal, key) => <SpecificationTag deal={deal} key={key} />,
      isVisible: (deal: ListDeal) =>
        isRussia && (deal.status === DealStatusTypes.AGREED || deal.status === DealStatusTypes.CONTRACT_SIGNED),
    },
    {
      render: (deal, key) => <Tag1C deal={deal} key={key} />,
      isVisible: (deal: ListDeal) => isRussia && deal.status === DealStatusTypes.SHIPMENT_COMPLETE,
    },
    {
      render: (deal, key) => <ContractTag deal={deal} key={key} />,
      isVisible: (deal: ListDeal) => isRussia && deal.status === DealStatusTypes.AGREED,
    },
    {
      render: (deal, key) => <ContributionMarginTag deal={deal} key={key} />,
      isVisible: (deal: ListDeal) => isRussia && deal.status === DealStatusTypes.SHIPMENT_COMPLETE,
    },
  ]

  return tags
}
