import { ButtonFullProps, defaultTheme, Icons, Progress } from '@agro-club/agroclub-shared'
import { FC, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components'
import { AButton } from '../Analytics'

const SelectButton = styled(AButton)`
  margin: 0 0 0 8px;

  @media (max-width: ${defaultTheme.queries.mobile}) {
    width: 100%;
    margin: 16px 0 0 0;
  }
`

export type FormSelectButtonProps = {
  action: () => void
  eventId: string
  intent?: ButtonFullProps['intent']
  icon?: ButtonFullProps['Icon']
  size?: ButtonFullProps['size']
  disabled?: boolean
  children?: ReactNode
  progress?: Progress
}

export const FormSelectButton: FC<FormSelectButtonProps> = ({
  action,
  eventId,
  intent = 'approve',
  icon = Icons.IconPlus,
  size = 'big',
  disabled,
  children,
  progress,
}) => {
  return (
    <SelectButton
      onClick={action}
      id={eventId}
      Icon={icon}
      intent={intent}
      size={size}
      disabled={disabled}
      progress={progress}
    >
      {children}
    </SelectButton>
  )
}

export const ResponsiveSelectAddButton: FC<FormSelectButtonProps> = ({ children, ...otherProps }) => {
  const { t } = useTranslation('common')
  const isMobile = useMediaQuery({ query: `(max-width: ${defaultTheme.queries.mobile})` })

  return (
    <>
      {!isMobile && <FormSelectButton {...otherProps} />}
      {isMobile && (
        <FormSelectButton size="biggest" {...otherProps}>
          {children || t('add')}
        </FormSelectButton>
      )}
    </>
  )
}
