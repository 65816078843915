import { refetchFunc } from 'modules/domain/common/hooks'
import { User } from 'modules/domain/user/types'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { VerifiedTitle, Verified, WrapperIcon } from '../../styled'
import { Icons } from '@agro-club/agroclub-shared'
import { isBrazil } from 'env'
import { KycConfirmModal } from './ConfirmModals/KycConfirmModal'
import PermissionCheck from 'views/components/PermissionCheck/PermissionCheck'

type Props = {
  userRefetch: refetchFunc
  user: User
}

export const KycVerify = ({ user, userRefetch }: Props) => {
  const { t } = useTranslation('user')

  const [kycVerifyModal, setKycVerifyModal] = useState(false)

  if (!isBrazil || !user.profile) return null

  return (
    <>
      <PermissionCheck scope={['users.can_verify_as_kyc']}>
        <Verified
          isVerified={user.profile.is_verified_by_kyc}
          isActive={user.is_active}
          onClick={() => setKycVerifyModal(true)}
        >
          <div>
            <WrapperIcon>
              <Icons.IconCrops />
            </WrapperIcon>
            <VerifiedTitle>{t('verify_kyc')}</VerifiedTitle>
          </div>
        </Verified>
      </PermissionCheck>

      {kycVerifyModal && (
        <KycConfirmModal onClose={() => setKycVerifyModal(false)} refetch={userRefetch} userId={user.id} />
      )}
    </>
  )
}
