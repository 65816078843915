import { TruncatedValue } from 'views/components/TruncatedValue/TruncatedValue'
import { defaultTheme, ExternalLink, Icons, Text } from '@agro-club/agroclub-shared'
import { KeyValue } from 'views/components/KeyValue/KeyValue'
import { Export, ExportDocumentType } from 'modules/domain/export/types'
import { DocumentFile } from 'modules/domain/types'
import { Card } from 'views/components/Card/Card'
import useFormatDate from 'hooks/useFormatDate'
import { useTranslation } from 'react-i18next'
import { DASH } from 'modules/constants'
import styled from 'styled-components'
import { FC } from 'react'

type Props = {
  exportData: Export
}

const BlockWithDoc = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${defaultTheme.color.secondary50};
  padding-bottom: 12px;
`
const Files = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px 0;
`
const FileItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0 4px;
  svg {
    width: 18px;
    height: 18px;
    path {
      fill: ${defaultTheme.color.secondaryPlaceholder3};
    }
  }
`

const DocumentBlock: FC<{ label: string; value: string; files: DocumentFile[] }> = ({ label, value, files }) => (
  <BlockWithDoc>
    <KeyValue label={label}>{value}</KeyValue>
    <Files>
      {files.map((file, index) => (
        <FileItem key={index}>
          <ExternalLink href={file.uploaded_file} target="_blank" style={{ maxWidth: '200px' }}>
            <Text typography="bodyMedium">
              <TruncatedValue hint={file.original_filename}>{file.original_filename}</TruncatedValue>
            </Text>
          </ExternalLink>
          <Icons.IconClip />
        </FileItem>
      ))}
    </Files>
  </BlockWithDoc>
)

export const CommonInfo: FC<Props> = ({ exportData }) => {
  const { t } = useTranslation('export')
  const formatDate = useFormatDate()

  const filterDocumentsByType = (type: ExportDocumentType) => {
    return exportData.documents.filter((doc) => doc.document_type === type)
  }
  return (
    <Card.GapableContent>
      <Card.Title>{t('commonInfo')}</Card.Title>
      <Card.Grid>
        <DocumentBlock
          files={filterDocumentsByType(ExportDocumentType.CONTRACT)}
          value={exportData.number || DASH}
          label={t('contractNumber')}
        />
        <DocumentBlock
          value={exportData.unique_contract_number || DASH}
          files={filterDocumentsByType(ExportDocumentType.UNK)}
          label={t('unk')}
        />
        <DocumentBlock
          value={exportData.ship_owner?.name || DASH}
          files={filterDocumentsByType(ExportDocumentType.SHIP_OWNER)}
          label={t('shipowner')}
        />
        <DocumentBlock
          value={exportData.export_broker?.name || DASH}
          files={filterDocumentsByType(ExportDocumentType.EXPORT_BROKER)}
          label={t('exportBroker')}
        />
        <DocumentBlock
          value={exportData.freight_broker?.name || DASH}
          files={filterDocumentsByType(ExportDocumentType.FREIGHT_BROKER)}
          label={t('freightBroker')}
        />
        <DocumentBlock
          value={exportData.insurance?.name || DASH}
          files={filterDocumentsByType(ExportDocumentType.INSURANCE)}
          label={t('insurance')}
        />
        <DocumentBlock
          value={exportData.surveyor?.name || DASH}
          files={filterDocumentsByType(ExportDocumentType.SURVEYOR)}
          label={t('surveyor')}
        />
        <Card.Grid cols={3} maxColumnWidth={'167px'}>
          <KeyValue label={t('contractDate')}>{formatDate(exportData.contract_date) || DASH}</KeyValue>
          <KeyValue label={t('consignmentDate')}>{formatDate(exportData.consignment_date) || DASH}</KeyValue>
          <KeyValue label={t('viaDubai')}>{exportData.through_dubai ? t('common:yes') : t('common:no')}</KeyValue>
        </Card.Grid>
        <KeyValue label={t('buyer')}>{exportData?.purchaser?.name || DASH}</KeyValue>

        <Card.Grid cols={2} maxColumnWidth={'263px'}>
          <KeyValue label={t('vesselName')}>{exportData?.ship?.name || DASH}</KeyValue>
          <KeyValue label={t('transshipment')}>{exportData.warehouse?.title || DASH}</KeyValue>
        </Card.Grid>
      </Card.Grid>
    </Card.GapableContent>
  )
}
