import { FormikProps } from 'formik'
import { FC, useMemo } from 'react'
import { SharedModalInputs } from 'views/components/SharedModal'
import FormSelectShared from 'views/components/FormSelectShared'
import { endpoints } from 'modules/endpoints'
import { useTranslation } from 'react-i18next'
import { SharedModalContent, SharedModalSecondContent, SharedModalSubTitle } from 'views/components/SharedModal/styled'
import styled from 'styled-components'
import { FormInput } from 'views/components/form/FormInput'
import { isBrazil, isRussia } from 'env'
import { countryFlags } from '../countryFlags'
import { ShortFormControl } from 'views/styled/common'

interface Props {
  formik: FormikProps<any>
  isAverageRates?: boolean
  isEditModal?: boolean
}

const ControlLabelWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  margin-top: 2px;
  svg {
    height: 20px;
    width: 20px;
  }
`

const InputsWrapper = styled.div<{ itemsPerColumn: number }>`
  display: grid;
  row-gap: 16px;
  column-gap: 112px;
  grid-template: ${({ itemsPerColumn }) => `repeat(${itemsPerColumn}, 1fr) / repeat(2, 1fr)`};
  grid-auto-flow: column;
`

export const ModalContent: FC<Props> = ({ formik, isEditModal, isAverageRates }) => {
  const { t } = useTranslation('rates')
  const itemsPerColumn = Math.ceil(formik.values.rates.length / 2)

  const regionsFilter = useMemo(
    () => ({
      parent_id: formik.values.countryId,
    }),
    [formik.values.countryId],
  )

  const getCountryLabel = (dto) => {
    const Icon = countryFlags[dto.country_code]
    return (
      <ControlLabelWrapper>
        <Icon />
        &nbsp;
        {dto.name}
      </ControlLabelWrapper>
    )
  }

  return (
    <SharedModalContent>
      <SharedModalInputs>
        {isRussia && !isEditModal && (
          <ShortFormControl>
            <FormSelectShared
              label={t('modal.countries')}
              placeholder=""
              formik={formik}
              onChange={() => formik.setFieldValue('sale_regions', [])}
              fieldName="countryId"
              showSearch={false}
              getLabel={getCountryLabel}
              endpoint={endpoints.countries()}
              simpleApiFormat
            />
          </ShortFormControl>
        )}
        <FormSelectShared
          label={t('modal.regions')}
          formik={formik}
          fieldName="sale_regions"
          getLabel={(dto) => dto.name}
          endpoint={endpoints.provinces()}
          filter={regionsFilter}
          simpleApiFormat
          disabled={(isEditModal && isBrazil) || isAverageRates}
          multiple
        />

        <FormSelectShared
          label={t('modal.logistician')}
          formik={formik}
          fieldName="responsible_logisticians"
          getLabel={(dto) => dto.full_name}
          endpoint={endpoints.dispatchingLogisticians()}
          multiple
          disabled={isAverageRates}
        />
      </SharedModalInputs>

      <SharedModalSecondContent>
        <SharedModalSubTitle>{t('modal.subTitle')}</SharedModalSubTitle>
        <InputsWrapper itemsPerColumn={itemsPerColumn}>
          {formik.values.rates.map((rate, index) => (
            <FormInput
              key={rate.distance_range.upper}
              error={formik?.errors?.rates?.[index]?.rate}
              label={rate.distance_range.upper}
              formik={formik}
              field={`rates[${index}].rate`}
              type="number"
              labelWidth={144}
            />
          ))}
        </InputsWrapper>
      </SharedModalSecondContent>
    </SharedModalContent>
  )
}
