import { CurrencySign, Export } from 'modules/domain/export/types'
import { Text, defaultTheme } from '@agro-club/agroclub-shared'
import formatNumber from 'modules/utils/numbers/formatNumber'
import formatPrice from 'modules/utils/numbers/formatPrice'
import { Card } from 'views/components/Card/Card'
import { useTranslation } from 'react-i18next'
import { Price } from '../../components/Price'
import styled from 'styled-components'
import { Rates } from '../Rates'
import { FC } from 'react'

const StyledCardContainer = styled(Card.Container).attrs({ intent: 'backgroundPrimary' })`
  width: 432px;
`

const NameCost = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Hr = styled.div`
  height: 1px;
  background: ${defaultTheme.color.secondary100};
`
type Props = {
  exportData: Export
}
export const CostInfo: FC<Props> = ({ exportData }) => {
  const { t } = useTranslation('export')
  return (
    <StyledCardContainer>
      <Card.GapableContent noPaddingForEmpty={false}>
        <NameCost>
          <Text typography="bodyMedium">{t('contractValue')}</Text>
          <Text typography="titleH4">
            {`${formatNumber(exportData.price, true)} ${t('formatPrice', {
              currency: CurrencySign[exportData.price_currency],
            })}`}
          </Text>
        </NameCost>
        <Rates exportData={exportData} />
        <Price
          localizedPrice={exportData.through_dubai_localized_price}
          currency={exportData.through_dubai_price_currency}
          price={exportData.through_dubai_price}
          label={t('priceThroughDubai')}
        />
        <Price
          localizedPrice={exportData.freight_cost_localized_price}
          currency={exportData.freight_currency}
          price={exportData.freight_cost}
          label={t('freight')}
        />
        <Price
          localizedPrice={exportData.warehouse_cost_localized_price}
          currency={exportData.warehouse_currency}
          price={exportData.warehouse_cost}
          label={t('transshipment')}
        />
        <Price
          localizedPrice={exportData.storage_cost_localized_price}
          currency={exportData.storage_currency}
          price={exportData.storage_cost}
          label={t('storage')}
        />
        <Price
          localizedPrice={exportData.export_broker_cost_localized_price}
          currency={exportData.export_broker_currency}
          price={exportData.export_broker_cost}
          label={t('broker')}
        />
        <Price
          localizedPrice={exportData.freight_broker_cost_localized_price}
          currency={exportData.freight_broker_currency}
          price={exportData.freight_broker_cost}
          label={t('freightBroker')}
        />
        <Price
          localizedPrice={exportData.documents_cost_localized_price}
          currency={exportData.documents_currency}
          price={exportData.documents_cost}
          label={t('documents')}
        />
        <Price
          localizedPrice={exportData.duty_cost_localized_price}
          currency={exportData.duty_currency}
          price={exportData.duty_cost}
          label={t('toll')}
        />
        <Price
          localizedPrice={exportData.insurance_cost_localized_price}
          currency={exportData.insurance_currency}
          price={exportData.insurance_cost}
          label={t('insurance')}
        />
        <Price
          localizedPrice={exportData.surveyor_cost_localized_price}
          currency={exportData.surveyor_currency}
          price={exportData.surveyor_cost}
          label={t('surveyor')}
        />
        <Hr />
        <NameCost>
          <Text typography="bodyMedium">{t('total')}</Text>
          <Text typography="titleH4">{formatPrice(exportData.total_margin, true)}</Text>
        </NameCost>
      </Card.GapableContent>
    </StyledCardContainer>
  )
}
