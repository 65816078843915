import { DeliveryPeriodData } from 'views/components/DealCard/DeliveryPeriod'
import { CompanyTooltip, StatusCompany } from '../DealCard'
import { formatQuantity } from 'modules/utils/numbers/formatQuantity'
import { formatDistance } from 'modules/utils/numbers/formatDistance'
import { formatTarif } from 'modules/utils/numbers/formatTarif'
import { refetchFunc } from 'modules/domain/common/hooks'
import { BestBatchCardForm } from './BestBatchCardForm'
import { ProfileType } from 'modules/domain/user/types'
import { ListDeal } from 'modules/domain/deal/types'
import { Text } from '@agro-club/agroclub-shared'
import { useTranslation } from 'react-i18next'
import { WrapperCard } from './PositionCard'
import styled from 'styled-components'
import { Company } from '../styled'
import { FC } from 'react'
import { DefaultTooltip } from 'views/components/DefaultTooltip/DefaultTooltip'

const Content = styled.div`
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px 0;
`

type Props = {
  onClick: (id: string | null) => void
  cardData: { id: string }
  refetch: refetchFunc
  selected: boolean
  deal: ListDeal
}

export const BestCard: FC<Props> = ({ selected, onClick, cardData, deal, refetch }) => {
  const { t } = useTranslation('deal')

  return (
    <WrapperCard selected={selected} onClick={() => onClick(cardData.id)}>
      <Content>
        <div>
          <CompanyTooltip tooltipContent={`${t('seller')} ${deal.sale_bid.company.name}`}>
            <Company>{deal.sale_bid.company.name}</Company>
          </CompanyTooltip>
          {StatusCompany(deal.sale_bid, ProfileType.seller)}
        </div>
        <div>
          <CompanyTooltip tooltipContent={`${t('buyer')} ${deal.purchase_bid.company.name}`}>
            <Company>{deal.purchase_bid.company.name}</Company>
          </CompanyTooltip>
          {StatusCompany(deal.purchase_bid, ProfileType.customer)}
        </div>
        <DefaultTooltip
          tooltipContent={[t('product_volume'), deal.season ? t('season').toLowerCase() : null]
            .filter(Boolean)
            .join(', ')}
        >
          <Text typography="bodyLarge">
            {`${deal.product.title}, ${formatQuantity(deal.quantity)}`}
            {deal.season && `, ${deal.season.name}, ${formatDistance(deal.distance)}`}
          </Text>
        </DefaultTooltip>
        <DefaultTooltip tooltipContent={t('fields.deliveryPeriod')}>
          <DeliveryPeriodData data={deal} />
        </DefaultTooltip>
        <DefaultTooltip tooltipContent={t('actual_margin')}>
          {deal?.margin_per_kg && <Text typography="accentLarge">${formatTarif(deal.margin_per_kg)}</Text>}
        </DefaultTooltip>

        {selected && <BestBatchCardForm onCancel={() => onClick(null)} deal={deal} refetch={refetch} />}
      </Content>
    </WrapperCard>
  )
}
